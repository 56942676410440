import { Ref } from "vue";
import { PieceId } from "../../shared/ids";
import { Piece } from "../../shared/rehorse";
import { cachedLoad } from "./cached-loader";
import { Handle } from "../loader-handle";

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
export const isIos = isSafari && navigator.maxTouchPoints > 0;

export const getAudioUri = (
  group: string,
  pieceId: PieceId,
  piece: Ref<Piece>,
) => {
  // apple does not support opus well and ogg/vorbis does not do well either
  if (isSafari && piece.value.mp3) {
    return `groups/${group}/audio/mp3/${piece.value.mp3}`;
  }
  return `groups/${group}/audio/${pieceId}`;
};

const l = async (uri: string) => {
  return await cachedLoad({ uri }, (blob) => Promise.resolve(blob));
};

export const audioLoaderOptions = {
  getter: l,
  checker: (v: Blob) => {
    // sometimes Blobs get returned as empty
    return v.size !== 0;
  },
};

const createAudioHandle = (uri: Ref<string>) => {
  return new Handle(uri, audioLoaderOptions);
};

export { createAudioHandle };
