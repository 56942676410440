<template>
  <Top />
  <div v-if="piece">
    <div v-if="status.isReady" class="bar">
      <img
        class="icon-button left"
        src="../svg/media-skip-backward.svg"
        alt="start"
        @click="updatePosition(0)"
      />
      <img
        v-if="!playing"
        class="icon-button left"
        src="../svg/media-playback-start.svg"
        alt="play"
        @click="play"
      />
      <img
        v-else
        class="icon-button left"
        src="../svg/media-playback-pause.svg"
        alt="pause"
        @click="pause"
      />
      <TempoSelector class="right" @tempo-changed="tempoChange"></TempoSelector>
      <VolumeSelector
        v-if="!isIos"
        class="right"
        @volume-changed="volumeChange"
      ></VolumeSelector>
    </div>
    <div v-if="editing">
      <RangeEditor
        :piece="piece"
        :position="position"
        @change-position="updatePosition"
        @range="setRange"
        @save="save"
      ></RangeEditor>
      <button v-if="editable" class="button is-small" @click="editing = false">
        Cancel
      </button>
    </div>
    <div v-else>
      <h1 class="title player-head">{{ piece.title }}</h1>
      <Slider
        v-if="lastMark"
        :marks="piece.marks"
        :position="position"
        :duration="lastMark.time"
        :range-start="rangeStart"
        :range-end="rangeEnd"
        @change-position="updatePosition"
      ></Slider>
      <LoadingProgress
        v-if="lastMark"
        :data="loadingProgress"
        :duration="lastMark.time"
      ></LoadingProgress>
      <RangeSelector
        v-if="piece.marks.length > 2"
        :marks="piece.marks"
        :range-start="rangeStartIndex"
        :range-end="rangeEndIndex"
        @range-start="setRangeStartIndex"
        @range-end="setRangeEndIndex"
      ></RangeSelector>
      <button v-if="editable" class="button is-small" @click="editing = true">
        Edit
      </button>
    </div>
    <audio
      ref="audio"
      autoplay="true"
      loop="true"
      preload="auto"
      @timeupdate="onTimeUpdateListener"
      @play="onTimeUpdateListener"
      @pause="onTimeUpdateListener"
      @progress="updateLoadingProgress"
    ></audio>
  </div>
</template>

<script setup lang="ts">
import { computed, onUnmounted, ref, watch, Ref, toRef } from "vue";
import { Mark } from "../../shared/rehorse";
import Top from "../components/Top.vue";
import TempoSelector from "../components/TempoSelector.vue";
import VolumeSelector from "../components/VolumeSelector.vue";
import RangeSelector from "../components/RangeSelector.vue";
import RangeEditor from "../components/RangeEditor.vue";
import Slider from "../components/Slider.vue";
import LoadingProgress from "../components/LoadingProgress.vue";
import type { BufferedRange } from "../components/BufferedRange";
import { useRehorseStore } from "../store";
import type { PieceId } from "../../shared/ids";
import { createAudioHandle, getAudioUri, isIos } from "../loaders/audio";

const props = defineProps<{
  group: string;
  piece: PieceId;
}>();

type Timer = ReturnType<typeof setInterval> | undefined;

const store = useRehorseStore();
const piece = computed(() => store.getPiece(props.group, props.piece));
const handle = createAudioHandle(
  toRef(() => getAudioUri(props.group, props.piece, piece)),
);
const status = computed(() => handle.status.value);
const audio: Ref<HTMLAudioElement | null> = ref(null);
const editing = ref(false);
const playing = ref(false);
const volume = ref(0.5);
const rangeStart = ref(0);
const rangeStartIndex = ref(0);
const rangeEnd = ref(0);
const rangeEndIndex = ref(0);
const position = ref(0);
const positionPolling: Ref<Timer | undefined> = ref();
const loadingProgress: Ref<BufferedRange[]> = ref([]);
const lastMark = computed(
  () => piece.value.marks[piece.value.marks.length - 1],
);
watch(status, () => {
  if (status.value.isReady && audio.value) {
    audio.value.src = URL.createObjectURL(status.value.data);
    volumeChange(volume.value);
  }
});

watch(piece, adjustRangeEnd, { immediate: true });

function adjustRangeEnd() {
  rangeEndIndex.value = piece.value.marks.length - 2;
  const lastRangeMark = piece.value.marks[piece.value.marks.length - 1];
  if (lastRangeMark) {
    rangeEnd.value = lastRangeMark.time;
  }
}

function updatePolling() {
  if (audio.value && !audio.value.paused && editing.value) {
    if (!positionPolling.value) {
      positionPolling.value = setInterval(() => {
        if (audio.value) {
          onTimeUpdate(audio.value.currentTime);
        }
      }, 50);
    }
  } else if (positionPolling.value !== undefined) {
    clearInterval(positionPolling.value);
    positionPolling.value = undefined;
  }
}
function onTimeUpdateListener() {
  if (audio.value) {
    onTimeUpdate(audio.value.currentTime);
    playing.value = !audio.value.paused;
  }
}
function onTimeUpdate(currentTime: number) {
  position.value = Math.round(10 * currentTime) / 10;
  clampPosition();
  updatePolling();
  updateLoadingProgress();
}
function updateLoadingProgress() {
  if (audio.value) {
    const buffered = audio.value.buffered;
    const progress = [];
    for (let i = 0; i < buffered.length; i += 1) {
      progress.push({ start: buffered.start(i), end: buffered.end(i) });
    }
    loadingProgress.value = progress;
  }
}
function updatePosition(pos: number) {
  if (audio.value) {
    audio.value.currentTime = pos;
  }
  clampPosition();
}
async function play() {
  if (audio.value) {
    await audio.value.play();
  }
}
function pause() {
  if (audio.value) {
    audio.value.pause();
  }
}
function tempoChange(tempo: number) {
  if (audio.value) {
    audio.value.playbackRate = tempo;
  }
}
function volumeChange(newVolume: number) {
  volume.value = newVolume;
  if (audio.value) {
    // On iOS, the volume cannot be changed, so the volume control should be
    // hidden. The volume is always 1 even after writing a different value.
    audio.value.volume = newVolume;
  }
}
function setRangeStartIndex(markIndex: number) {
  const startMark = piece.value.marks[markIndex];
  const endMark = piece.value.marks[markIndex + 1];
  if (startMark && endMark) {
    rangeStartIndex.value = markIndex;
    rangeStart.value = startMark.time;
    if (rangeEndIndex.value < markIndex) {
      rangeEndIndex.value = markIndex;
      rangeEnd.value = endMark.time;
    }
  }
  clampPosition();
}
function setRangeEndIndex(markIndex: number) {
  const startMark = piece.value.marks[markIndex];
  const endMark = piece.value.marks[markIndex + 1];
  if (startMark && endMark) {
    if (rangeStartIndex.value > markIndex) {
      rangeStartIndex.value = markIndex;
      rangeStart.value = startMark.time;
    }
    rangeEndIndex.value = markIndex;
    rangeEnd.value = endMark.time;
  }
  clampPosition();
}
function setRange(start: number, end: number) {
  rangeStart.value = start;
  rangeEnd.value = end;
  clampPosition();
}
function clampPosition() {
  if (position.value < rangeStart.value || position.value > rangeEnd.value) {
    position.value = rangeStart.value;
    if (audio.value) {
      audio.value.currentTime = rangeStart.value;
    }
  }
}
async function save(title: string, newmarks: Mark[]) {
  await store.changePiece(props.group, {
    index: props.piece,
    title,
    marks: newmarks,
  });
  editing.value = false;
}
const editable: boolean = store.canEdit(props.group);

onUnmounted(() => {
  clearInterval(positionPolling.value);
});
</script>
