<template>
  <Top />
  <h2 class="title">Desired files</h2>
  <div>{{ numberAvailable }} of {{ numberDesired }} are present.</div>
  <table class="table">
    <tr v-for="[url, available] in desiredUrls" :key="url">
      <td>{{ url }}</td>
      <td>
        <span v-if="available">☑</span>
        <button v-else @click="retrieve(url)">☐</button>
      </td>
    </tr>
  </table>
  <h2 class="title">Cached files</h2>
  <div>
    <button class="button" :disabled="!files?.length" @click="clear()">
      Clear cache
    </button>
  </div>
  <table class="table">
    <tr>
      <th align="center" @click="setOrder('date')">date</th>
      <th align="center" @click="setOrder('url')">url</th>
      <th align="center" @click="setOrder('size')">size</th>
    </tr>
    <tr v-for="file in files" :key="file.url">
      <td>{{ formatDate(file.date) }}</td>
      <td>{{ file.url }}</td>
      <td align="right">{{ formatSize(file.size) }}</td>
      <td>
        <button class="button" @click="removeCacheEntry(file.url)">🗑️</button>
      </td>
    </tr>
    <tr>
      <td></td>
      <td align="right">Total:</td>
      <td>{{ formatSize(totalSize) }}</td>
    </tr>
  </table>
</template>
<script setup lang="ts">
import { computed, ref, watch } from "vue";
import Top from "../components/Top.vue";
import { liveCacheFiles, Order, clearCache, deleteFromCache } from "../cachedb";
import { getDesiredUrls, retrieveUrl } from "../cache";

const order = ref<Order>("date");
const files = liveCacheFiles(order);
const desiredUrls = ref<Map<string, boolean>>(new Map());
const numberDesired = ref(0);
const numberAvailable = ref(0);
const totalSize = computed(() =>
  files.value.reduce((sum, v) => sum + v.size, 0),
);
const setOrder = (o: Order) => {
  order.value = o;
};

const formatDate = (d: Date) =>
  d.toISOString().substring(0, 16).replace("T", " ");
const formatSize = (size: number) => {
  if (size > 1024 * 1024) {
    return `${Math.floor(size / 1024 / 1024)} M`;
  }
  if (size > 1024) {
    return `${Math.floor(size / 1024)} k`;
  }
  return `${size}`;
};
const updateDesiredUrls = async () => {
  const urls = await getDesiredUrls();
  const newDesiredUrls = new Map();
  for (const [url] of urls) {
    newDesiredUrls.set(url, false);
  }
  numberDesired.value = urls.size;
  numberAvailable.value = 0;
  for (const file of files.value) {
    if (newDesiredUrls.has(file.url)) {
      newDesiredUrls.set(file.url, true);
      numberAvailable.value += 1;
    }
  }
  desiredUrls.value = newDesiredUrls;
};

watch(() => files, updateDesiredUrls, { immediate: true });

const updateList = async () => {
  return updateDesiredUrls();
};

watch([order, files], updateList, { immediate: true });

const clear = async () => {
  await clearCache();
  await updateList();
};

const removeCacheEntry = async (uri: string) => {
  await deleteFromCache(uri);
  await updateList();
};

const retrieve = async (url: string) => {
  await retrieveUrl(url);
  await updateDesiredUrls();
};
</script>
<style>
table > tr > th {
  cursor: pointer;
}
</style>
