import { cacheFile, deleteFromCache, getFileFromCache } from "../cachedb";
type ConverterFn<T> = (blob: Blob) => Promise<T>;

const get = async <T>(uri: string, fn: ConverterFn<T>) => {
  // support relative urls in the test environment (Node)
  const url = new URL(uri, location.href);
  const response = await fetch(url);
  if (!response.ok) {
    if (response.status === 401 || response.status === 403) {
      // the user must log in
    }
    throw new Error(await response.text());
  }
  const blob = await response.blob();
  const result = await fn(blob);
  try {
    await cacheFile(uri, blob);
  } catch (e: unknown) {
    console.error(uri, e);
  }
  return result;
};

export interface Options<T> {
  uri: string;
  checkForUpdate?: boolean;
  updateCallback?: (t: T) => void;
}

export const cachedLoad = async <T>(
  options: Options<T>,
  // function to convert the retrieved blob,
  // if this function fails by throwing an error,
  // the blob will no be stored in the cache
  fn: ConverterFn<T>,
): Promise<T> => {
  let cacheEntry;
  try {
    cacheEntry = await getFileFromCache(options.uri);
  } catch (e: unknown) {
    console.error(e);
  }
  if (cacheEntry) {
    if (options.checkForUpdate) {
      void get(options.uri, fn).then((data) => {
        if (options.updateCallback) {
          options.updateCallback(data);
        }
        return;
      });
    }
    try {
      return await fn(cacheEntry.blob);
    } catch (_e: unknown) {
      // the cached value is not valid with current code
      // delete it and get a new value
      await deleteFromCache(options.uri);
    }
  }
  return get(options.uri, fn);
};
