<template>
  <p>
    Volume:
    <select class="select" @change="setVolume">
      <option
        v-for="n in 10"
        :key="11 - n"
        :value="11 - n"
        :selected="11 - n === volume"
      >
        {{ 11 - n }}
      </option>
    </select>
  </p>
</template>
<script setup lang="ts">
import { ref } from "vue";

const emit = defineEmits<(e: "volumeChanged", pos: number) => void>();

const storageKey = "playerVolume";

const initialVolume = (): number => {
  const v = localStorage.getItem(storageKey);
  if (v) {
    const volume = parseInt(v, 10);
    if (!isNaN(volume) && volume > 0 && volume <= 10) {
      emitVolume(volume);
      return volume;
    }
  }
  emitVolume(5);
  return 5;
};

const saveVolume = (volume: number) => {
  localStorage.setItem(storageKey, volume.toString());
  emitVolume(volume);
};

const volume = ref(initialVolume());

function setVolume(e: Event) {
  const select = e.target;
  if (select instanceof HTMLSelectElement && +select.value) {
    saveVolume(+select.value);
  }
}
function emitVolume(newVolume: number) {
  emit("volumeChanged", Math.pow(newVolume / 10, 4));
}
</script>
