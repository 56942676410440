<template>
  <Top />
  <div v-if="status.isReady">
    <div v-for="group in status.data" :key="group.groupname">
      <router-link
        :to="{ name: 'group', params: { group: group.groupname } }"
        class="panel-block"
      >
        {{ group.name }}
      </router-link>
    </div>
  </div>
  <div v-else-if="status.isLoading">LOADING</div>
  <div v-else-if="status.isError">
    {{ status.error }}
  </div>
</template>
<script setup lang="ts">
import Top from "../components/Top.vue";
import { computed, onErrorCaptured, onUnmounted, ref } from "vue";
import { groupListLoader } from "../loaders/groups";

const uri = ref("api/groups");
const handle = groupListLoader.get(uri);
const status = computed(() => handle.value.status.value);

onUnmounted(() => {
  groupListLoader.release(handle);
});
onErrorCaptured((_err: unknown, _instance, info: string) => {
  console.error(info);
});
</script>
