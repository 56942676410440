import { AnnotationItem } from "../../shared/annotations";

interface Mode {
  isView: boolean;
  isDraw: boolean;
  isEdit: boolean;
  isInsertText: boolean;
}

export interface View extends Mode {
  variant: "view";
  isView: true;
  isDraw: false;
  isEdit: false;
  isInsertText: false;
}
export const viewMode: View = {
  variant: "view",
  isView: true,
  isDraw: false,
  isEdit: false,
  isInsertText: false,
};
export interface Draw extends Mode {
  variant: "draw";
  isView: false;
  isDraw: true;
  isEdit: false;
  isInsertText: false;
}
export const drawMode: Draw = {
  variant: "draw",
  isView: false,
  isDraw: true,
  isEdit: false,
  isInsertText: false,
};
export interface Edit extends Mode {
  variant: "edit";
  isView: false;
  isDraw: false;
  isEdit: true;
  isInsertText: false;
  selectedAnnotation: AnnotationItem | undefined;
}
export const editMode = (
  selectedAnnotation: AnnotationItem | undefined = undefined,
): Edit => {
  return {
    variant: "edit",
    isView: false,
    isDraw: false,
    isEdit: true,
    isInsertText: false,
    selectedAnnotation,
  };
};
export interface InsertText extends Mode {
  variant: "textInsert";
  isView: false;
  isDraw: false;
  isEdit: false;
  isInsertText: true;
  insertText: string;
}
export const insertText = (text: string): InsertText => {
  return {
    variant: "textInsert",
    isView: false,
    isDraw: false,
    isEdit: false,
    isInsertText: true,
    insertText: text,
  };
};

export type PdfViewMode = View | Draw | Edit | InsertText;

export type PageFit = "custom" | "fit-width" | "fit-height";

export interface PdfViewOptions {
  mode: PdfViewMode;
  rotation: number;
  pageFit: PageFit;
  color: string;
}
