import { Ref, shallowRef, ShallowRef, watch } from "vue";

export interface Loading<T> {
  readonly variant: "Loading";
  isLoading: true;
  isError: false;
  isReady: false;
  promise: Promise<T | undefined>;
}
function loading<T>(promise: Promise<T>): Loading<T> {
  return {
    variant: "Loading",
    isLoading: true,
    isError: false,
    isReady: false,
    promise,
  };
}
export interface LoadingError {
  readonly variant: "LoadingError";
  isLoading: false;
  isError: true;
  isReady: false;
  error: Error;
}
function loadingError(error: Error): LoadingError {
  return {
    variant: "LoadingError",
    isLoading: false,
    isError: true,
    isReady: false,
    error,
  };
}
export interface Ready<T> {
  readonly variant: "Ready";
  isLoading: false;
  isError: false;
  isReady: true;
  data: T;
}
function ready<T>(data: T): Ready<T> {
  return {
    variant: "Ready",
    isLoading: false,
    isError: false,
    isReady: true,
    data,
  };
}
export interface Released {
  readonly variant: "Released";
  isLoading: false;
  isError: false;
  isReady: false;
}
function released(): Released {
  return {
    variant: "Released",
    isLoading: false,
    isError: false,
    isReady: false,
  };
}
declare type AssetStatus<T> = Loading<T> | LoadingError | Ready<T> | Released;

type LoaderFn<T> = (
  uri: string,
  callback?: (freshData: T) => void,
) => Promise<T>;
export interface LoaderOptions<T> {
  checker?: (t: T) => boolean;
  getter: LoaderFn<T>;
}

export class Handle<T> {
  uri: Ref<string>;
  status: ShallowRef<AssetStatus<T>> = shallowRef(released());
  constructor(uri: Ref<string>, options: LoaderOptions<T>) {
    this.uri = uri;
    watch(
      uri,
      () => {
        this.status.value = loading(runFn(options, this));
      },
      { immediate: true },
    );
  }
  async wait(): Promise<T | undefined> {
    if (this.status.value.isLoading) {
      return this.status.value.promise;
    }
    return Promise.resolve(undefined);
  }
  release() {
    this.status.value = released();
  }
  revive(options: LoaderOptions<T>) {
    const hasValidValue =
      this.status.value.isReady &&
      (!options.checker || options.checker(this.status.value.data));
    if (!(hasValidValue || this.status.value.isLoading)) {
      this.status.value = loading(runFn(options, this));
    }
  }
}

const runFn = async <T>(
  options: LoaderOptions<T>,
  handle: Handle<T>,
): Promise<T | undefined> => {
  const loadedUri = handle.uri.value;
  try {
    const ok = await options.getter(loadedUri, (freshData) => {
      handle.status.value = ready(freshData);
    });
    if (handle.uri.value === loadedUri) {
      handle.status.value = ready(ok);
      return ok;
    }
    return;
  } catch (err: unknown) {
    if (handle.uri.value !== loadedUri) {
      return;
    }
    const error =
      err instanceof Error
        ? err
        : new Error(`Could not load ${loadedUri}`, { cause: err });
    handle.status.value = loadingError(error);
    return;
  }
};
