<template>
  <input ref="colorInput" type="color" :value="color" @change="colorChange" />
</template>
<script setup lang="ts">
import { computed, ref, Ref } from "vue";
import { PdfViewOptions } from "./PdfViewOptions";
const props = defineProps<{
  options: PdfViewOptions;
}>();
const emit =
  defineEmits<
    (e: "colorChange", item: string | undefined, color: string) => void
  >();
const colorInput: Ref<HTMLInputElement | undefined> = ref();
const item = computed(() => {
  const mode = props.options.mode;
  return mode.isEdit ? mode.selectedAnnotation : undefined;
});
const color = computed(() => {
  let c = item.value?.color ?? props.options.color;
  if (c === "black") {
    c = "#000000";
  }
  return c;
});
function colorChange(e: Event) {
  if (e.target instanceof HTMLInputElement) {
    emit("colorChange", item.value?.id, e.target.value);
  }
}
</script>
