<template>
  <router-link
    v-for="[id, piece] in props.pieces"
    :key="id"
    :to="{ name: 'player', params: { piece: id } }"
    class="panel-block"
  >
    <span class="panel-icon">
      <span v-if="piece.marks.length > 2">🎼</span>
      <span v-else>🎵</span>
    </span>
    {{ piece.title }}
  </router-link>
</template>

<script setup lang="ts">
import { Piece } from "../../shared/rehorse";

const props = defineProps<{
  group: string;
  pieces: Map<string, Piece>;
}>();
</script>
