// SPDX-FileCopyrightText: 2023 Jos van den Oever <rehorse@vandenoever.info>
//
// SPDX-License-Identifier: AGPL-3.0-only

import { Ajv } from "ajv";
import { Value } from "@sinclair/typebox/value";
import AjvFormats, { FormatName } from "ajv-formats";
import { Result, ok, err } from "./result.js";
import {
  FormatRegistry,
  TSchema,
  Type,
  StaticDecode,
  StaticEncode,
} from "@sinclair/typebox";
import { Id, isId, isSha256, Sha256 } from "./ids.js";

const { default: addFormats } = AjvFormats;
const ajvFormats: FormatName[] = [
  "date-time",
  "email",
  "hostname",
  "uri",
  "uuid",
];
// an ajv instance that can be shared
const ajv = new Ajv({});
// type checking of ajv-formats modules does not work well with nodenext
// eslint-disable-next-line @typescript-eslint/no-unsafe-call
addFormats(ajv, ajvFormats);
ajvFormats.forEach((f) => {
  FormatRegistry.Set(f, () => true);
});

function json_check(key: string, value: unknown) {
  if (value instanceof Map) {
    throw new Error(`Found a Map instead of JSON for value of "${key}".`);
  }
  if (value instanceof Set) {
    throw new Error(`Found a Set instead of JSON for value of "${key}".`);
  }
  return value;
}

export function create_io<T extends TSchema>(
  schema: T,
  references: TSchema[] = [],
) {
  const compiled_schema = ajv.compile(schema);
  type D = StaticDecode<T>;
  type E = StaticEncode<T>;
  const decode = (input: unknown): Result<D, Error> => {
    const valid = compiled_schema(input);
    if (!valid) {
      const errors = compiled_schema.errors;
      console.error(errors);
      if (errors === null || errors === undefined) {
        return err(new Error("rehorse data is not valid"));
      } else {
        return err(new Error(errors.toString()));
      }
    }
    try {
      const v = Value.Decode(schema, references, input);
      return ok(v);
    } catch (e) {
      return err(new Error("Error parsing with Ajv", { cause: e }));
    }
  };
  const encode = (v: D): string => {
    const encoded: E = Value.Encode(schema, references, v);
    return JSON.stringify(encoded, json_check, 2) + "\n";
  };
  return { decode, encode };
}

/// Convert an Object with values to a Map with values.
/// The reverse is done with Object.fromEntries()
function convert<I extends Id, T>(input: Record<string, T>): Map<I, T> {
  const map = new Map<I, T>();
  for (const [key, value] of Object.entries(input)) {
    if (isId<I>(key)) {
      map.set(key, value);
    } else {
      throw Error(`${key} is not a valid key.`);
    }
  }
  return map;
}
function convertSha256<I extends Sha256, T>(
  input: Record<string, T>,
): Map<I, T> {
  const map = new Map<I, T>();
  for (const [key, value] of Object.entries(input)) {
    if (isSha256<I>(key)) {
      map.set(key, value);
    } else {
      throw Error(`${key} is not a valid key.`);
    }
  }
  return map;
}
function convertBack<T, E>(
  encode: (v: T) => E,
  input: Map<string, T>,
): Record<string, E> {
  const object: Record<string, E> = {};
  for (const [key, value] of input.entries()) {
    object[key] = encode(value);
  }
  return object;
}
const uuidPattern =
  "^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$";
export const uuidSchema = Type.String({
  type: "string",
  pattern: "^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$",
  $id: "#/$defs/uuid",
});
export const uuidSchemaRef = Type.Ref(uuidSchema);
const sha256Pattern = "^[0-9a-f]{64}$";
export const sha256Schema = Type.String({
  type: "string",
  pattern: "^[0-9a-f]{64}$",
  $id: "#/$defs/sha256",
});
export const sha256SchemaRef = Type.Ref(sha256Schema);

export function TypeMapId<I extends Id, T extends TSchema>(
  schema: T,
  references: TSchema[] = [],
) {
  type D = StaticDecode<typeof schema>;
  type E = StaticEncode<typeof schema>;
  const encoder = (d: D): E => Value.Encode(schema, references, d);
  return Type.Transform(
    Type.Record(Type.String({ pattern: uuidPattern }), schema, {
      additionalProperties: false,
    }),
  )
    .Decode(convert<I, StaticDecode<typeof schema>>)
    .Encode((v) => convertBack(encoder, v));
}
export function TypeMapSha256<I extends Sha256, T extends TSchema>(
  schema: T,
  references: TSchema[] = [],
) {
  type D = StaticDecode<typeof schema>;
  type E = StaticEncode<typeof schema>;
  const encoder = (d: D): E => Value.Encode(schema, references, d);
  return Type.Transform(
    Type.Record(Type.String({ pattern: sha256Pattern }), schema, {
      additionalProperties: false,
    }),
  )
    .Decode(convertSha256<I, D>)
    .Encode((v) => convertBack(encoder, v));
}

function moveEntryToEnd(value: object, key: string) {
  if (key in value) {
    const k = key as keyof object;
    const v = value[k];
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete value[k];
    value[k] = v;
  }
}

interface SchemaInfo {
  $schema: string;
  $id: string;
  title: string;
  description: string;
}

export function ajvToJsonSchema(info: SchemaInfo, schema: TSchema): string {
  let idPosition = 0;
  function cleanSchema(key: string, value: unknown) {
    // $id should not be in the exported schema
    if (key === "$id") {
      if (idPosition > 0) {
        return undefined;
      }
      idPosition += 1;
    }
    // make the order of items in the schema structures more logical
    if (value instanceof Object) {
      for (const name of [
        "pattern",
        "format",
        "minItems",
        "uniqueItems",
        "minimum",
        "exclusiveMaximum",
        "additionalProperties",
        "$defs",
      ]) {
        moveEntryToEnd(value, name);
      }
    }
    return value;
  }
  const object = {
    ...info,
    ...Type.Strict(schema),
  };
  return JSON.stringify(object, cleanSchema, 2) + "\n";
}
