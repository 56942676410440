import { Ref, shallowRef, ShallowRef, watch } from "vue";
import { Handle, LoaderOptions } from "./loader-handle";

class HandleRef<T> extends Handle<T> {
  refCount = 1;
}

const createHandleRef = <T>(
  loaderState: Map<string, HandleRef<T>>,
  options: LoaderOptions<T>,
  uri: Ref<string>,
): HandleRef<T> => {
  const existingHandle = loaderState.get(uri.value);
  if (existingHandle) {
    existingHandle.refCount += 1;
    existingHandle.revive(options);
    return existingHandle;
  }
  const handle = new HandleRef<T>(uri, options);
  loaderState.set(uri.value, handle);
  return handle;
};

const releaseHandle = <T>(
  loaderState: Map<string, HandleRef<T>>,
  uri: string,
) => {
  const handle = loaderState.get(uri);
  if (handle === undefined) {
    return;
  }
  handle.refCount -= 1;
  // do delayed garbage collection
  setTimeout(() => {
    if (handle.refCount === 0) {
      loaderState.delete(uri);
      handle.release();
    }
  }, 600000);
};

const createLoader = <T>(options: LoaderOptions<T>) => {
  const loaderState = new Map<string, HandleRef<T>>();
  return {
    get(uri: Ref<string>): ShallowRef<Handle<T>> {
      const handle = shallowRef(createHandleRef(loaderState, options, uri));
      watch(uri, (_newUri: string, oldUri: string) => {
        handle.value = createHandleRef(loaderState, options, uri);
        releaseHandle(loaderState, oldUri);
      });
      return handle;
    },
    release(handle: ShallowRef<Handle<T>>) {
      releaseHandle(loaderState, handle.value.uri.value);
    },
  };
};
export { createLoader };
