<template>
  <g stroke="black" @click="emit('delete')">
    <circle :cx="0" :cy="0" :r="10" fill="white" />
    <line :x1="5" :x2="-5" :y1="5" :y2="-5" />
    <line :x1="-5" :x2="5" :y1="5" :y2="-5" />
    ></g
  >
</template>
<script setup lang="ts">
const emit = defineEmits<(e: "delete") => void>();
</script>
