<template>
  <nav class="panel">
    <p class="panel-heading">People</p>
    <div class="level">
      <div class="control level-left">
        <input
          v-model="name"
          class="input"
          type="text"
          placeholder="Name"
          @keyup.enter="add"
        />
      </div>
      <div class="control level-right">
        <button class="button" :disabled="!nameIsAcceptable()" @click="add">
          Add
        </button>
      </div>
    </div>
    <span v-for="[id, person] in persons.people" :key="id" class="panel-block">
      {{ person.name }}
    </span>
  </nav>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useRehorseStore } from "../store";

const props = defineProps<{
  group: string;
}>();

const store = useRehorseStore();
const persons = store.getPersons(props.group);
const name = ref("");

function nameIsAcceptable() {
  if (name.value === "") {
    return false;
  }
  if (persons.hasPerson({ name: name.value })) {
    return false;
  }
  return true;
}

function add() {
  if (!nameIsAcceptable()) {
    return;
  }
  persons.addPerson({ name: name.value });
  name.value = "";
}
</script>
