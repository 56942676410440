import { Ref } from "vue";
import { cachedLoad } from "./cached-loader";
import { Handle } from "../loader-handle";

const l = async (uri: string) => {
  return await cachedLoad({ uri }, async (blob) => {
    return new Uint8Array(await blob.arrayBuffer());
  });
};

const pdfLoaderOptions = {
  getter: l,
  checker: (v: Uint8Array) => {
    // sometimes ArrayBuffers get returned as empty
    return v.length !== 0;
  },
};

const createPdfHandle = (uri: Ref<string>) => {
  return new Handle(uri, pdfLoaderOptions);
};

export { pdfLoaderOptions, createPdfHandle };
