<template>
  <text
    ref="root"
    :class="{ edit: edit }"
    :x="text.x"
    :y="text.y"
    stroke-width="0"
    :fill="text.color"
    >{{ text.text }}</text
  >
  <AnnotationDeleteButton
    v-if="hasFocus"
    :transform="`translate(${round(text.x + 22)}, ${round(text.y - 22)})`"
    @delete="emit('delete')"
  />
</template>
<script setup lang="ts">
import { round } from "./draw-utils";
import { AnnotationText } from "../../shared/annotations";
import AnnotationDeleteButton from "./AnnotationDeleteButton.vue";

defineProps<{
  text: AnnotationText;
  edit: boolean;
  hasFocus: boolean;
}>();
const emit = defineEmits<(e: "delete") => void>();
</script>
<style>
text.edit {
  text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
}
</style>
