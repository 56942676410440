// SPDX-FileCopyrightText: 2023 Jos van den Oever <rehorse@vandenoever.info>
//
// SPDX-License-Identifier: AGPL-3.0-only

import {
  TypeMapId,
  TypeMapSha256,
  create_io,
  sha256Schema,
  sha256SchemaRef,
  uuidSchema,
} from "./ajv.js";
import { agendaSchema, musicEventSchema } from "./agenda.js";
import { createIdSchema, isSha256 } from "./ids.js";
import type {
  PersonId,
  PieceId,
  PlaylistId,
  ArrangementId,
  PartId,
} from "./ids.js";
import { StaticDecode, Type } from "@sinclair/typebox";
import { ajvToJsonSchema } from "./ajv.js";

const markSchema = Type.Object(
  {
    name: Type.String(),
    time: Type.Number({ minimum: 0, exclusiveMaximum: 10000 }),
  },
  { additionalProperties: false },
);

const personIdSchema = createIdSchema<PersonId>();
const arrangementIdSchema = createIdSchema<ArrangementId>();
const pieceIdSchema = Type.Transform(sha256SchemaRef)
  .Decode((v): PieceId => {
    if (isSha256<PieceId>(v)) {
      return v;
    } else {
      throw Error();
    }
  })
  .Encode((v) => v);
const pieceSchema = Type.Object(
  {
    title: Type.String(),
    filename: Type.String(),
    mimetype: Type.String(),
    opus: Type.Optional(sha256SchemaRef),
    mp3: Type.Optional(sha256SchemaRef),
    marks: Type.Array(markSchema, { minItems: 2 }),
  },
  { $id: "#/$defs/piece", additionalProperties: false },
);
const pieceSchemaRef = Type.Ref(pieceSchema);
const playlistItemSchema = Type.Object(
  {
    name: Type.Optional(Type.String()),
    arrangement: Type.Optional(arrangementIdSchema),
  },
  { additionalProperties: false },
);
const playlistSchema = Type.Object(
  {
    name: Type.String(),
    items: Type.Array(playlistItemSchema),
  },
  { additionalProperties: false },
);
const pdfSchema = Type.Object(
  {
    sha256: sha256SchemaRef,
    filename: Type.String(),
    pages: Type.Integer(),
  },
  { $id: "#/$defs/pdf", additionalProperties: false },
);
const pdfAjvSchema = { ...pdfSchema, $defs: { sha256: sha256Schema } };
const arrangementPartSchema = Type.Object(
  {
    pdf: sha256SchemaRef,
    start: Type.Integer(),
    end: Type.Integer(),
    rotation: Type.Integer(),
  },
  { additionalProperties: false },
);
const partsMemberSchema = Type.Array(arrangementPartSchema);
const arrangementSchema = Type.Object(
  {
    title: Type.String(),
    version: Type.Optional(Type.String()),
    composers: Type.Array(personIdSchema),
    arrangers: Type.Array(personIdSchema),
    lyricists: Type.Array(personIdSchema),
    recordings: Type.Array(pieceIdSchema),
    pdfs: Type.Array(Type.Ref(pdfSchema)),
    parts: TypeMapId<PartId, typeof partsMemberSchema>(partsMemberSchema, [
      sha256Schema,
    ]),
  },
  { additionalProperties: false },
);
const personSchema = Type.Object(
  {
    name: Type.String(),
  },
  {
    additionalProperties: false,
  },
);
const rehorseSchema = Type.Object(
  {
    agenda: Type.Ref(agendaSchema),
    pieces: TypeMapSha256<PieceId, typeof pieceSchemaRef>(pieceSchemaRef, [
      pieceSchema,
      sha256Schema,
    ]),
    playlists: TypeMapId<PlaylistId, typeof playlistSchema>(playlistSchema, [
      uuidSchema,
    ]),
    persons: TypeMapId<PersonId, typeof personSchema>(personSchema),
    arrangements: TypeMapId<ArrangementId, typeof arrangementSchema>(
      arrangementSchema,
      [sha256Schema, pdfSchema, uuidSchema],
    ),
  },
  { additionalProperties: false },
);
const rehorseAjvSchema = {
  ...rehorseSchema,
  $defs: {
    sha256: sha256Schema,
    uuid: uuidSchema,
    agenda: agendaSchema,
    musicEvent: musicEventSchema,
    pdf: pdfSchema,
    piece: pieceSchema,
  },
};
export const rehorseJsonSchema = () =>
  ajvToJsonSchema(
    {
      $schema: "http://json-schema.org/draft-07/schema#",
      $id: "https://rehorse.vandenoever.info/rehorse.schema.json",
      title: "Rehorse",
      description: "The Rehorse data",
    },
    rehorseAjvSchema,
  );
export type Rehorse = StaticDecode<typeof rehorseSchema>;
export type Piece = StaticDecode<typeof pieceSchema>;
export type Mark = StaticDecode<typeof markSchema>;
export type Playlist = StaticDecode<typeof playlistSchema>;
export type PlaylistItem = StaticDecode<typeof playlistItemSchema>;
export type Person = StaticDecode<typeof personSchema>;
export type Arrangement = StaticDecode<typeof arrangementSchema>;
export type ArrangementPart = StaticDecode<typeof arrangementPartSchema>;
export type Pdf = StaticDecode<typeof pdfSchema>;
export const io = create_io(rehorseAjvSchema, [
  agendaSchema,
  pieceSchema,
  sha256Schema,
  pdfSchema,
  uuidSchema,
  musicEventSchema,
]);
export const io_upload_pdf = create_io(pdfAjvSchema, [sha256Schema]);
const uploadPieceSchema = Type.Tuple([pieceIdSchema, pieceSchema]);
const uploadPieceAjvSchema = {
  ...uploadPieceSchema,
  $defs: {
    sha256: sha256Schema,
  },
};
export const io_upload_piece = create_io(uploadPieceAjvSchema, [sha256Schema]);

export function print(rehorse: Rehorse) {
  console.log(rehorse.pieces);
}

export function sortRehorse(rehorse: Rehorse) {
  const sorted = [...rehorse.arrangements.entries()].sort((a, b) =>
    a[1].title.localeCompare(b[1].title),
  );
  for (const value of sorted) {
    value[1].pdfs.sort((a, b) => a.filename.localeCompare(b.filename));
  }
  rehorse.arrangements = new Map(sorted);
  sortPieces(rehorse);
  const sortedPlaylists = [...rehorse.playlists.entries()].sort((a, b) =>
    b[1].name.localeCompare(a[1].name),
  );
  rehorse.playlists = new Map(sortedPlaylists);
  const sortedConcerts = [...rehorse.agenda.concerts.entries()].sort((a, b) =>
    a[1].start.localeCompare(b[1].start),
  );
  rehorse.agenda.concerts = new Map(sortedConcerts);
  const sortedRehearsals = [...rehorse.agenda.rehearsals.entries()].sort(
    (a, b) => a[1].start.localeCompare(b[1].start),
  );
  rehorse.agenda.rehearsals = new Map(sortedRehearsals);
}

/// sort the pieces by title
function sortPieces(data: Rehorse) {
  const sortedPieces = [...data.pieces.entries()].sort((a, b) => {
    const atitle = a[1].title.replace(/\W/g, "").toLowerCase();
    const btitle = b[1].title.replace(/\W/g, "").toLowerCase();
    return atitle.localeCompare(btitle);
  });
  data.pieces = new Map(sortedPieces);
}
