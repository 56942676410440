<template>
  <Top />
  <div class="panel">
    <div class="panel-heading">
      <div class="level is-mobile">
        <div class="level-left">Recordings</div>
        <div class="level-right">
          <!-- <span>🔍</span> -->
          <div v-if="store.canEdit(props.group)">
            <button v-if="!adding" class="button is-small" @click="add">
              Add
            </button>
            <button v-if="adding" class="button is-small" @click="cancel">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
    <Upload
      v-if="adding"
      accept="audio/*"
      :post-url="`groups/${props.group}/audio`"
      @uploaded="uploaded"
    />
    <Pieces v-if="!adding" :group="group" :pieces="pieces" />
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useRehorseStore } from "../store";
import Top from "../components/Top.vue";
import Pieces from "../components/Pieces.vue";
import Upload from "../components/Upload.vue";
import { io_upload_piece } from "../../shared/rehorse";

const props = defineProps<{
  group: string;
}>();

const store = useRehorseStore();
const router = useRouter();
const pieces = store.getPieces(props.group);
const adding = ref(false);
function add() {
  adding.value = true;
}
function cancel() {
  adding.value = false;
}
async function uploaded(index: number, total: number, response: unknown) {
  const result = io_upload_piece.decode(response);
  if (result.isErr) {
    throw result.value;
  }
  const [id, piece] = result.value;
  await store.addPiece(props.group, id, piece);
  if (total === 1) {
    // open the new file in the player if only one file was uploaded
    await router.push({
      name: "player",
      params: { group: props.group, piece: id },
    });
  } else if (index + 1 === total) {
    // when the last of multiple files was uploaded, go to the list of pieces
    adding.value = false;
  }
}
</script>
