<template>
  <router-link :to="{ name: 'user' }" class="level-item">
    <span v-if="store.user">
      {{ store.user.name }}
    </span>
    <span v-else> Login </span>
  </router-link>
</template>
<script setup lang="ts">
import { useRehorseStore } from "../store";
const store = useRehorseStore();
</script>
