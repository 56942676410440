<template>
  <Top />
  <div class="panel">
    <MusicEventEdit
      v-if="edit"
      :group="props.group"
      :event="rehearsalData"
      @change="change"
      @cancel="cancel"
    >
    </MusicEventEdit>
    <template v-else-if="rehearsalData">
      <div class="panel-heading">
        <div class="level is-mobile">
          <div
            class="level-left"
            :class="{ cancelled: rehearsalData.cancelled }"
          >
            {{ localeDate(rehearsalData.start) }}
          </div>
          <div class="level-right">
            <button
              v-if="store.canEdit(props.group)"
              class="button is-small"
              @click="edit = true"
            >
              Edit
            </button>
          </div>
        </div>
      </div>
      <div class="column">
        <dl class="name-value-grid">
          <dt>Start</dt>
          <dd>{{ localeTime(rehearsalData.start) }}</dd>
          <dt>End</dt>
          <dd>{{ localeTime(rehearsalData.end) }}</dd>
          <dt>Location</dt>
          <dd>{{ location() }}</dd>
          <dt>Remarks</dt>
          <dd>
            <p
              v-for="(p, index) in rehearsalData.remarks.split('\n')"
              :key="index"
            >
              {{ p }}
            </p>
          </dd>
        </dl>
      </div>
      <div class="panel-heading">Pieces to prepare</div>
      <Playlist :group="props.group" :playlist="rehearsalData.playlist" />
    </template>
  </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useRehorseStore } from "../store";
import Top from "../components/Top.vue";
import MusicEventEdit from "../components/MusicEventEdit.vue";
import Playlist from "../components/Playlist.vue";
import { localeDate, localeTime } from "../../shared/agenda";
import type { Rehearsal } from "../../shared/agenda";
import type { RehearsalId } from "../../shared/ids";

const props = defineProps<{
  group: string;
  rehearsal: RehearsalId;
}>();
const store = useRehorseStore();
const router = useRouter();
const agenda = store.getAgenda(props.group);
let rehearsalData = agenda.rehearsals.get(props.rehearsal) ?? null;
const edit = ref(!rehearsalData);
function location(): string {
  if (rehearsalData) {
    return agenda.locations.get(rehearsalData.location)?.name ?? "";
  }
  return "";
}
async function change(newRehearsal: Rehearsal) {
  agenda.rehearsals.set(props.rehearsal, newRehearsal);
  await store.save(props.group);
  rehearsalData = newRehearsal;
  edit.value = false;
}
function cancel() {
  if (rehearsalData) {
    edit.value = false;
  } else {
    router.go(-1);
  }
}
</script>
