<template>
  <Top />
  <h1 class="Title">An error occurred</h1>
  <div v-for="[index, error] of reversedErrors.entries()" :key="index">
    <pre v-if="error">{{ error }}</pre>
    <hr />
  </div>
</template>
<script setup lang="ts">
import { computed } from "vue";
import Top from "../components/Top.vue";
import { errors } from "../errors";

const reversedErrors = computed(() => [...errors.value].reverse());
</script>
