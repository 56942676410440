<template>
  <div
    ref="div"
    :class="{
      notouch: mode.isDraw || mode.isEdit,
    }"
    @pointerdown="pointerDown"
    @pointerup="endDrag"
    @pointermove="drag"
    @pointercancel="pointerEnd"
    @pointerout="pointerEnd"
    @pointerleave="pointerEnd"
    @click="click"
  >
    <svg
      width="100%"
      height="100%"
      :viewBox="viewBox()"
      xmlns="http://www.w3.org/2000/svg"
    >
      <DrawingItem
        v-for="item in items"
        :key="item.id"
        :item
        :edit="mode.isEdit"
        :has-focus="mode.isEdit && item === mode.selectedAnnotation"
        :translate="translation(item.id)"
        @delete="deleteItem(item.id)"
        @start-drag="startItemDrag"
        @change-focus="(hasFocus) => changeFocus(item, hasFocus)"
      />
    </svg>
  </div>
</template>
<script setup lang="ts">
import { Ref, ref } from "vue";
import DrawingItem from "./DrawingItem.vue";
import {
  Point,
  PageAnnotations,
  AnnotationItem,
  AnnotationText,
} from "../../shared/annotations";
import { createId } from "../../shared/ids";
import { editMode, PdfViewMode } from "./PdfViewOptions";
import { round } from "./draw-utils";

const props = defineProps<{
  widthPt: number;
  heightPt: number;
  scale: number;
  mode: PdfViewMode;
  color: string;
  annotations: PageAnnotations;
}>();
const emit = defineEmits<{
  (e: "change", annotations: PageAnnotations): void;
  (e: "moveItem", id: string, offset: Point): void;
  (e: "modeChange", mode: PdfViewMode): void;
}>();
const painting = ref(false);
const div: Ref<HTMLDivElement | undefined> = ref();
const items: Ref<AnnotationItem[]> = ref(props.annotations.items);
const dragStart: Ref<Point | undefined> = ref();
const dragPosition: Ref<Point | undefined> = ref();
const dragItem: Ref<string | undefined> = ref();
// time in milliseconds since epoch when the last time a path was edited
// This is used to know if a path should be continued with a gap or
// if a completely new path is added.
const lastPathEdit: Ref<number> = ref(0);

function viewBox(): string {
  return `0 0 ${props.widthPt} ${props.heightPt}`;
}
// Number of milliseconds between path additions after which a new
// path is created.
const maxContinuePathInterval = 3000;
function getLastPathD() {
  const i = items.value;
  const lastItem = i[i.length - 1];
  return lastItem?.type === "path" ? lastItem.d : undefined;
}
function addPath(d: Point[]) {
  const now = Date.now();
  const lastPathD = getLastPathD();
  if (
    lastPathD !== undefined &&
    lastPathEdit.value + maxContinuePathInterval > now
  ) {
    lastPathD.push(d);
  } else {
    items.value.push({
      type: "path",
      id: createId(),
      d: [d],
      color: props.color,
    });
  }
}
function pointerDown(e: PointerEvent) {
  if (!e.isPrimary) {
    return;
  }
  if (props.mode.isDraw) {
    painting.value = true;
    addPath([]);
    draw(e);
  }
}
function endDrag(e: PointerEvent) {
  if (!e.isPrimary) {
    return;
  }
  // remove if the path contains only one point
  const d = getLastPathD();
  if (d !== undefined && d.length > 0) {
    const c = d[d.length - 1];
    if (c === undefined || c.length <= 1) {
      d.pop();
    }
    if (d.length === 0) {
      items.value.pop();
    }
  }
  if (painting.value) {
    emit("change", { items: items.value });
  }
  const offset = dragOffset();
  if (offset) {
    if (dragItem.value) {
      emit("moveItem", dragItem.value, offset);
    }
  }
  dragStart.value = undefined;
  dragPosition.value = undefined;
  dragItem.value = undefined;
  painting.value = false;
}
function drag(e: PointerEvent) {
  if (!e.isPrimary) {
    return;
  }
  if (props.mode.isDraw) {
    draw(e);
  } else if (props.mode.isEdit && dragStart.value && div.value) {
    dragPosition.value = eventToPoint(e, div.value);
  } else {
    return;
  }
}
function pointerEnd() {
  dragStart.value = undefined;
  dragItem.value = undefined;
}
function eventToPoint(e: PointerEvent | MouseEvent, d: HTMLDivElement): Point {
  const scale = props.widthPt / d.clientWidth / props.scale;
  return {
    x: round(e.offsetX * scale),
    y: round(e.offsetY * scale),
  };
}
function draw(e: PointerEvent) {
  if (!painting.value || !div.value) return;
  const point = eventToPoint(e, div.value);
  const d = getLastPathD();
  if (d === undefined) {
    addPath([point]);
  } else {
    d[d.length - 1]?.push(point);
  }
  lastPathEdit.value = Date.now();
}
function deleteItem(id: string) {
  items.value = items.value.filter((e) => {
    return e.id !== id;
  });
  emit("modeChange", editMode());
  emit("change", { items: items.value });
}
function startItemDrag(e: PointerEvent, id: string) {
  if (props.mode.isEdit && div.value) {
    dragStart.value = eventToPoint(e, div.value);
    dragItem.value = id;
  }
}
function dragOffset(): Point | undefined {
  const from = dragStart.value;
  const to = dragPosition.value;
  if (from && to) {
    return { x: to.x - from.x, y: to.y - from.y };
  }
  return;
}
function translation(id: string): Point | undefined {
  if (id === dragItem.value) {
    return dragOffset();
  }
  return;
}
function click(e: MouseEvent) {
  if (props.mode.isEdit) {
    emit("modeChange", editMode());
  }
  if (!props.mode.isInsertText || !div.value) {
    return;
  }
  const point = eventToPoint(e, div.value);
  const item: AnnotationText = {
    type: "text",
    id: createId(),
    x: point.x,
    y: point.y,
    text: props.mode.insertText,
    color: props.color,
  };
  items.value.push(item);
  emit("change", { items: items.value });
  emit("modeChange", editMode(item));
}
function changeFocus(item: AnnotationItem, hasFocus: boolean) {
  emit("modeChange", editMode(hasFocus ? item : undefined));
}
</script>
<style>
div.notouch {
  touch-action: none;
}
svg text {
  /* avoid text selection in the annotations */
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}
</style>
