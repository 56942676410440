<template v-if="props.path.d">
  <!-- the path -->
  <path :d="drawPoints()" />
  <!-- a background to indicate editing -->
  <path
    v-if="props.edit"
    :stroke-opacity="hasFocus ? 0.2 : 0.1"
    stroke-width="20"
    :d="drawPoints()"
  />
  <AnnotationDeleteButton
    v-if="topRight && hasFocus"
    :transform="`translate(${round(topRight.x + 12)}, ${round(topRight.y - 12)})`"
    @delete="emit('delete')"
  />
</template>
<script setup lang="ts">
import { ref } from "vue";
import { Point, AnnotationPath } from "../../shared/annotations";
import { round } from "./draw-utils";
import AnnotationDeleteButton from "./AnnotationDeleteButton.vue";
const props = defineProps<{
  path: AnnotationPath;
  edit: boolean;
  hasFocus: boolean;
}>();
const emit = defineEmits<(e: "delete") => void>();

const topRight = ref<Point | undefined>();

function drawPoints() {
  const group = props.path.d;
  let path = "";
  if (group.length > 0) {
    let right = 0;
    let top = 10000;
    for (const c of group) {
      const first = c[0];
      if (first && c.length > 1) {
        right = Math.max(right, first.x);
        top = Math.min(top, first.y);
        topRight.value = first;
        path += `M${round(first.x)} ${round(first.y)}L`;
        for (const j of c.slice(1)) {
          path += `${round(j.x)} ${round(j.y)} `;
          right = Math.max(right, j.x);
          top = Math.min(top, j.y);
        }
      }
    }
    topRight.value = { x: right, y: top };
  }
  return path;
}
</script>
