<template>
  <nav class="menu navbar is-fixed-bottom">
    <div class="navbar-brand">
      <a
        role="button"
        class="navbar-burger"
        :class="{ 'is-active': isActive }"
        aria-label="menu"
        :aria-expanded="isActive"
        @click="toggleEditMode"
        ><span class="icon">&#9998;</span></a
      >
    </div>
  </nav>
</template>
<script setup lang="ts">
import { computed } from "vue";
import { editMode, PdfViewOptions, viewMode } from "./PdfViewOptions";

const props = defineProps<{
  options: PdfViewOptions;
}>();
const emit = defineEmits<(e: "change", options: PdfViewOptions) => void>();
const isActive = computed(
  () => props.options.mode.isEdit || props.options.mode.isInsertText,
);
function toggleEditMode() {
  emit("change", {
    ...props.options,
    mode: isActive.value ? viewMode : editMode(),
  });
}
</script>
<style>
nav.menu.navbar.is-fixed-bottom {
  left: auto;
  top: auto;
}
nav.is-fixed-bottom a.navbar-burger span {
  height: 0;
}
</style>
