<template>
  <div v-for="item in playlist.items" :key="item.name || ''">
    <p v-if="item.name" class="panel-block has-text-grey">
      <span class="panel-icon"></span>
      <em>{{ item.name }}</em>
    </p>
    <ArrangementLine
      v-else-if="item.arrangement"
      :id="item.arrangement"
      :group="props.group"
      :arrangement="store.getArrangement(props.group, item.arrangement)"
    />
  </div>
</template>

<script setup lang="ts">
import { useRehorseStore } from "../store";
import type { PlaylistId } from "../../shared/ids";
import ArrangementLine from "../components/ArrangementLine.vue";

const props = defineProps<{
  group: string;
  playlist: PlaylistId;
}>();

const store = useRehorseStore();

const playlist = store.getPlaylist(props.group, props.playlist);
</script>
