<template>
  <p>
    <span>Tempo:</span>
    <select ref="select" class="select" @change="setTempo">
      <option v-for="n in 25" :key="n" :value="val(n)" :selected="n === 13">
        {{ val(n) }}%
      </option>
    </select>
  </p>
</template>

<script setup lang="ts">
import { ref, Ref } from "vue";

const emit = defineEmits<(e: "tempoChanged", pos: number) => void>();

const select: Ref<HTMLSelectElement | null> = ref(null);

function setTempo() {
  if (select.value) {
    const value = +select.value.value;
    emit("tempoChanged", value / 100);
  }
}
function val(n: number) {
  return Math.round(100 * Math.pow(2, (n - 13) / 12));
}
</script>
