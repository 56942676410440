<template>
  <div>
    <Multiselect
      v-model="arrangement"
      placeholder="Arrangements"
      :multiple="false"
      :options="options"
      track-by="id"
      :taggable="true"
      label="label"
      @select="selectArrangement"
      @tag="addText"
      @focus="focus"
    >
    </Multiselect>
  </div>
  <draggable :list="selected" item-key="id" handle=".handle" @end="emitChange">
    <template #item="{ element, index }">
      <div class="panel-block is-block">
        <div class="level is-mobile">
          <div class="level-left">
            <span class="panel-icon handle">
              <span>{{ index + 1 }}</span>
            </span>
            <span v-if="element.item.arrangement" class="handle">{{
              element.label
            }}</span>
            <em v-else class="handle has-text-grey">{{ element.label }}</em>
          </div>
          <div class="level-right">
            <button class="delete" @click="remove(element.id)"></button>
          </div>
        </div>
      </div>
    </template>
  </draggable>
</template>
<script setup lang="ts">
import { computed, ref, Ref } from "vue";
import Multiselect from "vue-multiselect";
import draggable from "vuedraggable";
import { useRehorseStore } from "../store";
import { PlaylistItem } from "../../shared/rehorse";
import {
  PlaylistOption,
  getArrangementsAsOptions,
} from "../stores/arrangements";

const props = defineProps<{
  group: string;
  items: PlaylistItem[];
}>();
const emit = defineEmits<(e: "change", items: PlaylistItem[]) => void>();

const store = useRehorseStore();
const arrangements = computed((): PlaylistOption[] => {
  const a = store.getArrangements(props.group);
  const persons = store.getPersons(props.group);
  const [options, newIdCounter] = getArrangementsAsOptions(
    a,
    persons,
    idCounter,
  );
  idCounter = newIdCounter;
  return options;
});
const texts: Ref<PlaylistOption[]> = ref([]);
const options = computed((): PlaylistOption[] => {
  return arrangements.value.concat(texts.value);
});

// Each entry needs a unique id. The id of the arrangment or the name of an item
// cannot be used as id, since the list may contain duplicates.
let idCounter = 0;
const arrangement: Ref<PlaylistOption | null> = ref(null);
const selected: Ref<PlaylistOption[]> = ref([]);

// Retrieved the details of the selected items
const populateSelected = () => {
  const as = store.getArrangements(props.group);
  for (const item of props.items) {
    let a;
    if (item.arrangement) {
      a = as.value.arrangements.get(item.arrangement);
    }
    if (a) {
      selected.value.push({
        id: idCounter++,
        label: a.title,
        item,
      });
    } else {
      selected.value.push({
        id: idCounter++,
        label: item.name ?? "ERROR",
        item,
      });
    }
  }
};
populateSelected();

let multiselect: HTMLElement | null = null;
function focus(event: FocusEvent) {
  if (event.target instanceof HTMLElement) {
    multiselect = event.target;
  }
}
function selectArrangement() {
  if (!arrangement.value) {
    return;
  }
  const item = arrangement.value.item;
  selected.value.push({
    id: idCounter++,
    label: arrangement.value.label,
    item,
  });
  arrangement.value = null;
  emitChange();
  window.setTimeout(() => {
    if (multiselect) {
      multiselect.focus();
    }
  }, 300);
}
function addText(text: string) {
  const option = { id: idCounter++, label: text, item: { name: text } };
  texts.value.push(option);
  arrangement.value = option;
  selectArrangement();
  emitChange();
}
function remove(id: number) {
  const pos = selected.value.findIndex((e) => e.id === id);
  if (pos !== -1) {
    selected.value.splice(pos, 1);
  }
  emitChange();
}
function emitChange() {
  const items = selected.value.map((s) => s.item);
  emit("change", items);
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style>
.handle {
  cursor: move;
}
</style>
