<template>
  <button
    class="button"
    :class="{
      'is-inverted': isActive,
    }"
    @click="click"
  >
    <span :title class="icon" :style="'color:' + color">{{ text }}</span>
  </button>
</template>
<script setup lang="ts">
import { computed } from "vue";
import { PdfViewMode, editMode, insertText } from "./PdfViewOptions";

const props = defineProps<{
  color: string;
  mode: PdfViewMode;
  title: string;
  text: string;
}>();
const emit = defineEmits<(e: "change", mode: PdfViewMode) => void>();
const isActive = computed(() => {
  return props.mode.isInsertText && props.mode.insertText === props.text;
});
function click() {
  if (props.mode.isInsertText && props.mode.insertText === props.text) {
    emit("change", editMode());
  } else {
    emit("change", insertText(props.text));
  }
}
</script>
