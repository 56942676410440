<template>
  <p>
    <router-link
      :to="{
        name: 'arrangement',
        params: { arrangement: props.id, group: props.group },
      }"
      class="panel-block"
    >
      <span class="panel-icon">
        <template v-if="hasRecordings()">
          <span v-if="hasBars()">🎼</span>
          <span v-else>🎵</span>
        </template>
        <span v-else></span>
      </span>
      <span v-if="store.user" class="panel-icon">
        <span v-if="hasParts()">🗋</span>
      </span>
      <span>
        <b>{{ arrangement.title }}</b>
        <span v-if="props.persons">
          <template v-if="arrangement.composers.length">
            <span class="pl-1 pr-1">/ composed by</span>
            <span
              v-for="composer in props.arrangement.composers"
              :key="composer"
              class="pr-1"
            >
              {{ props.persons.people?.get(composer)?.name }}
            </span>
          </template>
          <template v-if="props.arrangement.arrangers.length">
            <span class="pr-1">/ arranged by</span>
            <span
              v-for="arranger in props.arrangement.arrangers"
              :key="arranger"
              class="pr-1"
            >
              {{ props.persons.people?.get(arranger)?.name }}
            </span>
          </template>
          <template v-if="props.arrangement.lyricists.length">
            <span class="pr-1">/ words by</span>
            <span
              v-for="lyricist in props.arrangement.lyricists"
              :key="lyricist"
              class="pr-1"
            >
              {{ props.persons.people?.get(lyricist)?.name }}
            </span>
          </template>
        </span>
      </span>
    </router-link>
  </p>
</template>
<script setup lang="ts">
import { useRehorseStore } from "../store";
import type { Arrangement } from "../../shared/rehorse";
import { Persons } from "../stores/people";
import type { ArrangementId } from "../../shared/ids";

const props = defineProps<{
  group: string;
  id: ArrangementId;
  arrangement: Arrangement;
  persons?: Persons;
}>();

const store = useRehorseStore();

function hasRecordings(): boolean {
  return props.arrangement.recordings.length > 0;
}

function hasBars(): boolean {
  return props.arrangement.recordings.some((r) => {
    const piece = store.getPiece(props.group, r);
    return piece.marks.length > 2;
  });
}

function hasParts(): boolean {
  return Object.keys(props.arrangement.parts).length > 0;
}
</script>
