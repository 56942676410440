<template>
  <div class="annotation-menu" :class="{ 'is-hidden': !isActive }">
    <div class="tabs mb-0">
      <ul>
        <li
          v-for="[id, range] of Object.entries(glyphs)"
          :key="id"
          :class="{ 'is-active': id === activeTab }"
        >
          <a class="is-size-7" @click="activateTab(id)">
            {{ range.desc }}
          </a>
        </li>
      </ul>
    </div>
    <AnnotationItemPanel
      :options
      @color-change="(i, c) => emit('colorChange', i, c)"
    />
    <div class="buttons">
      <PdfTextInsertButton
        v-for="g of tabGlyphs"
        :key="g.t"
        :text="g.t"
        :title="g.desc"
        :mode="options.mode"
        :color="options.color"
        @change="changeMode"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, Ref, ref } from "vue";
import PdfTextInsertButton from "./PdfTextInsertButton.vue";
import AnnotationItemPanel from "./AnnotationItemPanel.vue";
import glyphs from "../musical-glyphs.json";
import { PdfViewMode, PdfViewOptions } from "./PdfViewOptions";

const props = defineProps<{
  options: PdfViewOptions;
}>();
const emit = defineEmits<{
  (e: "change", options: PdfViewOptions): void;
  (e: "colorChange", item: string | undefined, color: string): void;
}>();

interface Glyph {
  t: string;
  desc: string;
}

function isRangeKey(
  key: PropertyKey,
  obj: typeof glyphs,
): key is keyof typeof glyphs {
  return key in obj;
}

const initialGlyphRange: keyof typeof glyphs = "dynamics";
const activeTab: Ref<keyof typeof glyphs> = ref(initialGlyphRange);
const tabGlyphs: Ref<Record<string, Glyph>> = ref(
  glyphs[initialGlyphRange].glyphs,
);

const isActive = computed(
  () => props.options.mode.isEdit || props.options.mode.isInsertText,
);

function activateTab(id: string) {
  if (isRangeKey(id, glyphs)) {
    activeTab.value = id;
    tabGlyphs.value = glyphs[id].glyphs;
  }
}
function changeMode(newMode: PdfViewMode) {
  emit("change", {
    ...props.options,
    mode: newMode,
  });
}
</script>
<style>
div.annotation-menu div.buttons {
  gap: 0;
}
div.annotation-menu {
  display: grid;
  grid-template-columns: 5fr 1fr;
  grid-template-rows: 1fr 3fr;
}
</style>
