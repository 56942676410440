<template>
  <Top />
  <nav class="panel">
    <router-link :to="{ name: 'agenda' }" class="panel-block">
      Agenda
    </router-link>
    <router-link :to="{ name: 'playlists' }" class="panel-block">
      Playlists
    </router-link>
    <router-link
      :to="{ name: 'arrangements', params: { group } }"
      class="panel-block"
    >
      Arrangements
    </router-link>
    <router-link :to="{ name: 'pieces' }" class="panel-block">
      Recordings
    </router-link>
    <router-link
      v-if="store.canEdit(props.group) && !store"
      :to="{ name: 'people', params: { group } }"
      class="panel-block"
    >
      People
    </router-link>
  </nav>
</template>

<script setup lang="ts">
import { useRehorseStore } from "../store";
import Top from "../components/Top.vue";

const props = defineProps<{
  group: string;
}>();

const store = useRehorseStore();
</script>
