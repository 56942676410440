<template>
  <div class="panel-heading">
    <div class="level is-mobile">
      <div class="level-left">Edit playlist</div>
      <div class="level-right">
        <button
          class="button is-small"
          :disabled="!playlistIsAcceptable()"
          @click="save"
        >
          Save
        </button>
        <button class="button is-small" @click="emit('cancel')">Cancel</button>
      </div>
    </div>
  </div>
  <div class="control level-left">
    <input v-model="name" class="input" type="text" placeholder="Title" />
  </div>
  <PlaylistEditInner :group="props.group" :items="items" @change="change" />
</template>
<script setup lang="ts">
import { ref, Ref } from "vue";
import { useRehorseStore } from "../store";
import type { Playlist, PlaylistItem } from "../../shared/rehorse";
import type { PlaylistId } from "../../shared/ids";
import PlaylistEditInner from "./PlaylistEditInner.vue";

const props = defineProps<{
  group: string;
  playlist: PlaylistId;
}>();
const emit = defineEmits<{
  (e: "change", playlist: Playlist): void;
  (e: "cancel"): void;
}>();

const store = useRehorseStore();
const name = ref("");
const items: Ref<PlaylistItem[]> = ref([]);

if (store.hasPlaylist(props.group, props.playlist)) {
  const pl = store.getPlaylist(props.group, props.playlist);
  name.value = pl.name;
  items.value = pl.items;
}
function playlistIsAcceptable() {
  return name.value !== "" && items.value.length > 0;
}
function change(newItems: PlaylistItem[]) {
  items.value = newItems;
}
function save() {
  if (!playlistIsAcceptable()) {
    return;
  }
  const playlist = {
    name: name.value,
    items: items.value,
  };
  emit("change", playlist);
}
</script>
