<template>
  <g
    ref="clickArea"
    :stroke="props.item.color"
    stroke-width="1"
    stroke-linejoin="round"
    stroke-linecap="round"
    fill="none"
    style="touch-action: none"
    focusable="false"
    :transform="transform(props.translate)"
    @pointerdown="pointerdown"
    @click="click"
    @keyup.delete="deleteItem"
  >
    <DrawingPath
      v-if="item.type === 'path'"
      v-bind="itemArgs"
      :path="item"
      @delete="deleteItem"
    />
    <DrawingText
      v-if="item.type === 'text'"
      v-bind="itemArgs"
      :text="item"
      @delete="deleteItem"
    />
  </g>
</template>
<script setup lang="ts">
import { computed, Ref, ref } from "vue";
import { AnnotationItem, Point } from "../../shared/annotations";
import DrawingPath from "./DrawingPath.vue";
import DrawingText from "./DrawingText.vue";
import { transform } from "./draw-utils";

const props = defineProps<{
  item: AnnotationItem;
  edit: boolean;
  hasFocus: boolean;
  translate: Point | undefined;
}>();
const emit = defineEmits<{
  (e: "delete"): void;
  (e: "startDrag", event: PointerEvent, item: string): void;
  (e: "changeFocus", hasFocus: boolean): void;
}>();

const clickArea: Ref<SVGPathElement | undefined> = ref();
const itemArgs = computed(() => {
  return {
    edit: props.edit,
    hasFocus: props.hasFocus,
  };
});
function pointerdown(e: PointerEvent) {
  emit("startDrag", e, props.item.id);
  emit("changeFocus", true);
  e.stopImmediatePropagation();
  e.preventDefault();
}
function click(e: MouseEvent) {
  if (props.edit) {
    emit("changeFocus", true);
    e.stopImmediatePropagation();
    e.preventDefault();
  }
}
function deleteItem() {
  emit("delete");
}
</script>
