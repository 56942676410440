<template>
  <div ref="slider" class="slider">
    <div class="range" :style="rangeStyle" />
    <div
      v-for="mark in marks"
      :key="mark.time"
      class="mark"
      :style="'left:' + perc(mark.time, duration)"
      @click="changePosition"
    >
      {{ mark.name }}
    </div>
    <div
      id="slider-position"
      class="pos"
      :style="'left:' + perc(position, duration)"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, Ref } from "vue";
import { Mark } from "../../shared/rehorse";

const props = defineProps<{
  marks: Mark[];
  position: number;
  duration: number;
  rangeStart: number;
  rangeEnd: number;
}>();

const emit = defineEmits<(e: "changePosition", pos: number) => void>();

const slider: Ref<HTMLDivElement | null> = ref(null);

const rangeStyle = computed(() => {
  const startTime = props.rangeStart;
  const endTime = props.rangeEnd;
  const start = Math.round((10000 * startTime) / props.duration) / 100;
  const end = 100 - Math.round((10000 * endTime) / props.duration) / 100;
  return `left: ${start}%; right: ${end}%;`;
});

function perc(time: number, duration: number) {
  return `${Math.round((10000 * time) / duration) / 100}%`;
}

function changePosition(evt: MouseEvent) {
  const s = evt.target;
  if (
    slider.value &&
    s instanceof HTMLElement &&
    s.parentNode === slider.value
  ) {
    const x = evt.offsetX + s.offsetLeft;
    const position = (props.duration * x) / slider.value.clientWidth;
    emit("changePosition", position);
  }
}
</script>
