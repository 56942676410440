// SPDX-FileCopyrightText: 2023 Jos van den Oever <rehorse@vandenoever.info>
//
// SPDX-License-Identifier: AGPL-3.0-only

import type { PartId } from "./ids.js";
import { TypeMapId, ajvToJsonSchema, create_io } from "./ajv.js";
import { Type, StaticDecode } from "@sinclair/typebox";

const lcNamePattern = "^[a-z][a-z0-9]*";
export const lcNameSchema = Type.String({ pattern: lcNamePattern });

const partSchema = Type.Object(
  {
    name: Type.String(),
    members: Type.Array(lcNameSchema, { uniqueItems: true }),
    backups: Type.Array(lcNameSchema, { uniqueItems: true }),
  },
  { additionalProperties: false },
);

export const groupSchema = Type.Object(
  {
    name: Type.String(),
    groupname: lcNameSchema,
    short: Type.Optional(Type.String()),
    website: Type.Optional(Type.String()),
    admins: Type.Array(lcNameSchema, { minItems: 1, uniqueItems: true }),
    members: Type.Array(lcNameSchema, { minItems: 1, uniqueItems: true }),
    parts: TypeMapId<PartId, typeof partSchema>(partSchema),
  },
  { additionalProperties: false },
);

export type Group = StaticDecode<typeof groupSchema>;
export type Part = StaticDecode<typeof partSchema>;

const groupArraySchema = Type.Array(groupSchema, {
  minItems: 1,
  uniqueItems: true,
});
export const group_io = create_io(groupArraySchema);

export const groupsJsonSchema = () =>
  ajvToJsonSchema(
    {
      $schema: "http://json-schema.org/draft-07/schema#",
      $id: "https://rehorse.vandenoever.info/config.schema.json",
      title: "Groups",
      description: "The group configuration of Rehorse",
    },
    groupArraySchema,
  );
