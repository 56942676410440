import { createLoader } from "../loader";
import { Group, group_io } from "../../shared/group";
import { cachedLoad, Options } from "./cached-loader";

const gL = async (uri: string, callback?: (t: Group[]) => void) => {
  const options: Options<Group[]> = { uri, checkForUpdate: true };
  if (callback) {
    options.updateCallback = callback;
  }
  return await cachedLoad(options, async (blob) => {
    const json: unknown = JSON.parse(await blob.text());
    const jsonResult = group_io.decode(json);
    if (jsonResult.isErr) {
      throw jsonResult.value;
    }
    return jsonResult.value;
  });
};

const groupListLoader = createLoader<Group[]>({ getter: gL });

export { groupListLoader };
