import Dexie, { type Table } from "dexie";
import { Sha256 } from "@aws-crypto/sha256-browser";
import { computed, ref, Ref } from "vue";

interface ShallowCachedFile {
  url: string;
  date: Date;
  size: number;
}

interface CachedFile extends ShallowCachedFile {
  sha256: string;
  blob: Blob;
}

const cachedb = new Dexie("CacheDatabase") as Dexie & {
  files: Table<CachedFile, string>;
};

// Schema declaration lists the properties that are indexed.
// Do not index large properties.
cachedb.version(1).stores({
  files: "url, date, size, sha256",
});

const hashValue = async (val: Blob) => {
  const hash = new Sha256();
  hash.update(await val.arrayBuffer());
  const hashBuffer = await hash.digest();
  const hashArray = Array.from(hashBuffer);
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
  return hashHex;
};

const deleteFromCache = async (url: string) => {
  try {
    await cachedb.files.delete(url);
  } catch (e: unknown) {
    console.error(e);
  }
};

const cacheFile = async (url: string, data: Blob) => {
  const sha256 = await hashValue(data);
  await cachedb.transaction("rw", cachedb.files, async () => {
    const oldData = await cachedb.files.get(url);
    if (oldData?.sha256 === sha256) {
      return;
    }
    if (oldData) {
      await deleteFromCache(url);
    }
    const entry = {
      url,
      date: new Date(),
      size: data.size,
      blob: data,
      sha256,
    };
    await cachedb.files.add(entry, url);
  });
};

const getFileFromCache = async (
  url: string,
): Promise<CachedFile | undefined> => {
  return cachedb.files.get(url);
};

export type Order = "url" | "date" | "size";

const cacheFileList = ref<ShallowCachedFile[]>([]);
const updateFilesList = async (): Promise<void> => {
  const l: ShallowCachedFile[] = [];
  await cachedb.files.each((v) => {
    l.push({ url: v.url, size: v.size, date: v.date });
  });
  cacheFileList.value = l;
};

setTimeout(() => void updateFilesList(), 1);
Dexie.on("storagemutated", updateFilesList);

const listCacheFiles = async (order: Order): Promise<ShallowCachedFile[]> => {
  return cachedb.files.orderBy(order).toArray();
};

const liveCacheFiles = (order: Ref<Order>) => {
  return computed(() => {
    let sorter: (a: ShallowCachedFile, b: ShallowCachedFile) => number = (
      a,
      b,
    ) => {
      if (a.url > b.url) {
        return 1;
      }
      if (b.url > a.url) {
        return -1;
      }
      return 0;
    };
    if (order.value === "date") {
      sorter = (a, b) => a.date.getTime() - b.date.getTime();
    }
    if (order.value === "size") {
      sorter = (a, b) => a.size - b.size;
    }
    const newArray = [...cacheFileList.value];
    newArray.sort(sorter);
    return newArray;
  });
};

const clearCache = async () => {
  return cachedb.files.clear();
};

export {
  cacheFile,
  getFileFromCache,
  listCacheFiles,
  liveCacheFiles,
  clearCache,
  deleteFromCache,
};
