<template>
  <Top />
  <div class="panel-heading">
    <div class="level is-mobile">
      <div class="level-left">Concerts</div>
      <div class="level-right">
        <button
          v-if="store.canEdit(props.group)"
          class="button is-small"
          @click="addConcert"
        >
          Add
        </button>
      </div>
    </div>
  </div>
  <div v-for="[id, concert] of upcoming(agenda.concerts)" :key="id">
    <router-link
      :to="{ name: 'concert', params: { concert: id } }"
      class="panel-block"
      :class="{ cancelled: concert.cancelled }"
    >
      {{ localeDateTime(concert.start) }}
    </router-link>
  </div>
  <div class="panel-heading">
    <div class="level is-mobile">
      <div class="level-left">Rehearsals</div>
      <div class="level-right">
        <button
          v-if="store.canEdit(props.group)"
          class="button is-small"
          @click="addRehearsal"
        >
          Add
        </button>
      </div>
    </div>
  </div>
  <div v-for="[id, rehearsal] of upcoming(agenda.rehearsals)" :key="id">
    <router-link
      :to="{ name: 'rehearsal', params: { rehearsal: id } }"
      class="panel-block"
      :class="{ cancelled: rehearsal.cancelled }"
    >
      {{ localeDateTime(rehearsal.start) }}
    </router-link>
  </div>
</template>
<script setup lang="ts">
import { useRouter } from "vue-router";
import { useRehorseStore } from "../store";
import Top from "../components/Top.vue";
import {
  createDateTime,
  localeDateTime,
  MusicEvent,
} from "../../shared/agenda";
import type { ConcertId, RehearsalId } from "../../shared/ids";
import { createId } from "../../shared/ids";

const props = defineProps<{
  group: string;
}>();

const store = useRehorseStore();
const router = useRouter();
const agenda = store.getAgenda(props.group);

async function addConcert() {
  const id = createId<ConcertId>();
  await router.push({
    name: "concert",
    params: { group: props.group, concert: id },
  });
}
async function addRehearsal() {
  const id = createId<RehearsalId>();
  await router.push({
    name: "rehearsal",
    params: { group: props.group, rehearsal: id },
  });
}
function* upcoming<I>(
  events: Map<I, MusicEvent>,
): IterableIterator<[I, MusicEvent]> {
  const min5Years = createDateTime(-5 * 365 * 24 * 3600000);
  const reversed = [...events.entries()].reverse();
  for (const [id, event] of reversed) {
    if (event.end >= min5Years) {
      yield [id, event];
    }
  }
}
</script>
