// SPDX-FileCopyrightText: 2023 Jos van den Oever <rehorse@vandenoever.info>
//
// SPDX-License-Identifier: AGPL-3.0-only

import type { ConcertId, LocationId, PlaylistId, RehearsalId } from "./ids.js";
import { createIdSchema } from "./ids.js";
import { StaticDecode, Type } from "@sinclair/typebox";
import { TypeMapId, uuidSchema } from "./ajv.js";

declare const isDateTimeId: unique symbol;
export type DateTime = string & { [isDateTimeId]: true };

function assertDateTime(input: string): asserts input is DateTime {
  if (!isDateTime(input)) {
    throw new Error(`${input} is not a valid Date`);
  }
}

export const isDateTime = (input: string): input is DateTime => {
  const date = Date.parse(input);
  return !isNaN(date);
};

export const currentDateTime = (): DateTime => {
  return createDateTime(0);
};

export function createDateTime(offset: number): DateTime {
  const datetime = new Date(Date.now() + offset).toISOString();
  assertDateTime(datetime);
  return datetime;
}

export function localeDateTime(dateTime: DateTime): string {
  const date = new Date(dateTime);
  return date.toLocaleString("nl", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
}

export function localeDate(dateTime: DateTime): string {
  const date = new Date(dateTime);
  return date.toLocaleDateString("nl", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });
}

export function localeTime(dateTime: DateTime): string {
  const date = new Date(dateTime);
  return date.toLocaleTimeString("nl", {
    timeStyle: "short",
  });
}

export const playlistIdSchema = createIdSchema<PlaylistId>();
//const concertIdSchema = createIdSchema<ConcertId>();
//const rehearsalIdSchema = createIdSchema<RehearsalId>();
const locationIdSchema = createIdSchema<LocationId>();

const dateTimeSchema = Type.Transform(Type.String({ format: "date-time" }))
  .Decode((v): DateTime => {
    if (isDateTime(v)) {
      return v;
    } else {
      throw Error();
    }
  })
  .Encode((v) => v);

export const musicEventSchema = Type.Object(
  {
    start: dateTimeSchema,
    end: dateTimeSchema,
    location: locationIdSchema,
    remarks: Type.String(),
    playlist: playlistIdSchema,
    cancelled: Type.Boolean(),
  },
  {
    $id: "#/$defs/musicEvent",
    additionalProperties: false,
  },
);
export const musicEventSchemaRef = Type.Ref(musicEventSchema);
export type MusicEvent = StaticDecode<typeof musicEventSchema>;

export type Rehearsal = MusicEvent;

export type Concert = MusicEvent;

const locationSchema = Type.Object(
  {
    name: Type.String(),
  },
  {
    additionalProperties: false,
  },
);
export type Location = StaticDecode<typeof locationSchema>;

export const agendaSchema = Type.Object(
  {
    concerts: TypeMapId<ConcertId, typeof musicEventSchemaRef>(
      musicEventSchemaRef,
      [musicEventSchema, uuidSchema],
    ),
    rehearsals: TypeMapId<RehearsalId, typeof musicEventSchemaRef>(
      musicEventSchemaRef,
      [musicEventSchema, uuidSchema],
    ),
    locations: TypeMapId<LocationId, typeof locationSchema>(locationSchema),
  },
  { $id: "#/$defs/agenda", additionalProperties: false },
);
export type Agenda = StaticDecode<typeof agendaSchema>;
