// SPDX-FileCopyrightText: 2023 Jos van den Oever <rehorse@vandenoever.info>
//
// SPDX-License-Identifier: AGPL-3.0-only

import type { Person } from "../../shared/rehorse";
import type { PersonId } from "../../shared/ids";
import { createId } from "../../shared/ids";

export class Persons {
  people: Map<PersonId, Person>;
  constructor(people: Map<PersonId, Person>) {
    this.people = people;
  }
  findPerson(name: string): PersonId | undefined {
    for (const [id, p] of this.people.entries()) {
      if (p.name === name) {
        return id;
      }
    }
    return;
  }
  hasPerson(person: Person): boolean {
    for (const p of this.people.values()) {
      if (p.name === person.name) {
        return true;
      }
    }
    return false;
  }
  addPerson(person: Person): PersonId {
    const id = createId<PersonId>();
    this.people.set(id, person);
    const sorted = [...this.people.entries()].sort((a, b) =>
      a[1].name.localeCompare(b[1].name),
    );
    this.people = new Map(sorted);
    return id;
  }
  listNames() {
    const names = [];
    for (const p of this.people.values()) {
      names.push(p.name);
    }
    return names;
  }
}
