<template>
  <div class="slider">
    <div
      v-for="range in props.data"
      :key="range.start"
      class="loading-progress-range"
      :style="style(range)"
    ></div>
  </div>
</template>
<script setup lang="ts">
import type { BufferedRange } from "./BufferedRange";

const props = defineProps<{
  data: BufferedRange[];
  duration: number;
}>();

function style(range: BufferedRange) {
  return `left: ${perc(range.start)}%; right: ${100 - perc(range.end)}%;`;
}
function perc(time: number): number {
  return Math.round((10000 * time) / props.duration) / 100;
}
</script>
<style>
div.loading-progress-range {
  position: absolute;
  background: black;
  height: 1px;
}
</style>
