<template>
  <input v-model="title" class="player-head input" style="width: 70%" />
  <Slider
    v-if="lastMark"
    :marks="newmarks"
    :position="position"
    :duration="lastMark.time"
    :range-start="rangeStart"
    :range-end="rangeEnd"
    @change-position="(pos: number) => emit('change-position', pos)"
  />
  <div class="range-selector">
    <div>
      <div>begin</div>
      <div>einde</div>
      <div>maat</div>
      <div>tijd (s)</div>
      <div />
    </div>
    <div
      v-for="(mark, n) in newmarks.slice(0, newmarks.length - 1)"
      :key="mark.time"
    >
      <div :class="rangeClass(n)" @click="setRangeStartIndex(n)" />
      <div :class="rangeClass(n)" @click="setRangeEndIndex(n)" />
      <div><input v-if="n > 0" v-model.lazy="mark.name" /></div>
      <div>
        <input
          v-if="n > 0"
          v-model.number.lazy="mark.time"
          inputmode="numeric"
          pattern="[0-9.]*"
          @blur="updateRange(n)"
        />
      </div>
      <div>
        <button v-if="n > 0" class="delete" @click="remove(n)">X</button>
      </div>
    </div>
  </div>
  <div>{{ position }}</div>
  <button class="button is-small" @click="save">save</button>
  druk op 'm' om een maatnummer in te voegen
</template>
<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from "vue";
import type { Piece, Mark } from "../../shared/rehorse";
import Slider from "./Slider.vue";

const props = defineProps<{
  piece: Piece;
  position: number;
}>();
const emit = defineEmits<{
  (e: "range", start: number, end: number): void;
  (e: "save", title: string, newmarks: Mark[]): void;
  (e: "change-position", pos: number): void;
}>();
const title = ref(props.piece.title);
const newmarks = props.piece.marks.slice();
const lastMark = computed(
  () => props.piece.marks[props.piece.marks.length - 1],
);
const rangeStart = ref(0);
const rangeStartIndex = ref(0);
const rangeEndIndex = ref(props.piece.marks.length - 2);
const initialRangeEnd = () => lastMark.value?.time ?? 0;
const rangeEnd = ref(initialRangeEnd());

function remove(n: number) {
  newmarks.splice(n, 1);
  if (n <= rangeEndIndex.value) {
    rangeEndIndex.value -= 1;
  }
  if (n <= rangeStartIndex.value) {
    rangeStartIndex.value -= 1;
  }
  const startMark = newmarks[rangeStartIndex.value];
  const endMark = newmarks[Math.min(rangeEndIndex.value + 1, newmarks.length)];
  if (startMark && endMark) {
    const start = startMark.time;
    const end = endMark.time;
    if (start !== rangeStart.value || end !== rangeEnd.value) {
      rangeStart.value = start;
      rangeEnd.value = end;
      emit("range", start, end);
    }
  }
}
function rangeClass(n: number) {
  if (rangeStartIndex.value <= n && n <= rangeEndIndex.value) {
    return "inrange";
  } else {
    return "outrange";
  }
}
function setRangeStartIndex(markIndex: number) {
  const startMark = newmarks[markIndex];
  const endMark = newmarks[markIndex + 1];
  if (startMark && endMark) {
    rangeStartIndex.value = markIndex;
    rangeStart.value = startMark.time;
    if (rangeEndIndex.value < markIndex) {
      rangeEndIndex.value = markIndex;
      rangeEnd.value = endMark.time;
    }
    emit("range", rangeStart.value, rangeEnd.value);
  }
}
function setRangeEndIndex(markIndex: number) {
  const startMark = newmarks[markIndex];
  const endMark = newmarks[markIndex + 1];
  if (startMark && endMark) {
    if (rangeStartIndex.value > markIndex) {
      rangeStartIndex.value = markIndex;
      rangeStart.value = startMark.time;
    }
    rangeEndIndex.value = markIndex;
    rangeEnd.value = endMark.time;
    emit("range", rangeStart.value, rangeEnd.value);
  }
}
function updateRange(n: number) {
  if (n === rangeStartIndex.value) {
    setRangeStartIndex(n);
  }
  if (n - 1 === rangeEndIndex.value) {
    setRangeEndIndex(n - 1);
  }
}
function key(e: KeyboardEvent) {
  if (
    e.which === 77 &&
    e.target instanceof HTMLElement &&
    e.target.localName !== "input"
  ) {
    // 'm'
    newmarks.forEach((mark, i) => {
      const time = mark.time;
      const position = props.position;
      if (time >= position) {
        if (time !== position) {
          newmarks.splice(i, 0, { name: "", time: position });
          if (i - 1 <= rangeEndIndex.value) {
            setRangeEndIndex(rangeEndIndex.value + 1);
          }
          if (i < rangeStartIndex.value) {
            setRangeStartIndex(rangeStartIndex.value + 1);
          }
        }
        return;
      }
    });
  }
}

function save() {
  for (const mark of newmarks) {
    if (typeof mark.time !== "number") {
      mark.time = parseInt(mark.time, 10);
    }
  }
  emit("save", title.value, newmarks);
}

onMounted(() => {
  window.addEventListener("keydown", key);
});

onUnmounted(() => {
  window.removeEventListener("keydown", key);
});
</script>
