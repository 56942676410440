<template>
  <nav class="breadcrumb level-item" aria-label="breadcrumbs">
    <ul>
      <li><router-link :to="{ name: 'home' }">home</router-link></li>
      <li v-if="params['group']">
        <router-link :to="{ name: 'group' }">{{ params["group"] }}</router-link>
      </li>
      <li v-if="name === 'playlists'" class="is-active">
        <router-link :to="{ name: 'playlists' }">playlists</router-link>
      </li>
      <li v-if="params['playlist']">
        <router-link :to="{ name: 'playlists' }">playlists</router-link>
      </li>
      <li v-if="name === 'arrangements'" class="is-active">
        <router-link :to="{ name: 'arrangements' }">arrangements</router-link>
      </li>
      <li v-if="params['arrangement']">
        <router-link :to="{ name: 'arrangements' }">arrangements</router-link>
      </li>
      <li v-if="name === 'pieces'" class="is-active">
        <router-link :to="{ name: 'pieces' }">recordings</router-link>
      </li>
      <li v-if="params['piece']">
        <router-link :to="{ name: 'pieces' }">recordings</router-link>
      </li>
      <li v-if="name === 'agenda'" class="is-active">
        <router-link :to="{ name: 'agenda' }">agenda</router-link>
      </li>
      <li v-if="name === 'concert' || name === 'rehearsal'">
        <router-link :to="{ name: 'agenda' }">agenda</router-link>
      </li>
      <li v-if="name === 'concert'" class="is-active">
        <router-link :to="{ name: 'concert' }">concert</router-link>
      </li>
      <li v-if="name === 'rehearsal'" class="is-active">
        <router-link :to="{ name: 'rehearsal' }">rehearsal</router-link>
      </li>
    </ul>
  </nav>
</template>
<script setup lang="ts">
import { computed } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
const params = computed(() => router.currentRoute.value.params);
const name = computed(() => router.currentRoute.value.name);
</script>
