import { Piece, Playlist, io } from "../../shared/rehorse";
import { cachedLoad } from "./cached-loader";
import {
  ConcertId,
  LocationId,
  PieceId,
  PlaylistId,
  RehearsalId,
} from "../../shared/ids";
import { Concert, Rehearsal, Location } from "../../shared/agenda";
import { Persons } from "../stores/people";
import { Arrangements } from "../stores/arrangements";
import { deleteFromCache } from "../cachedb";

export interface AgendaData {
  concerts: Map<ConcertId, Concert>;
  rehearsals: Map<RehearsalId, Rehearsal>;
  locations: Map<LocationId, Location>;
}

export interface RehorseData {
  agenda: AgendaData;
  pieces: Map<PieceId, Piece>;
  playlists: Map<PlaylistId, Playlist>;
  persons: Persons;
  arrangements: Arrangements;
}

const rL = async (
  uri: string,
  callback: (t: RehorseData) => void,
): Promise<RehorseData> => {
  return await cachedLoad(
    { uri, checkForUpdate: true, updateCallback: callback },
    async (blob) => {
      const jsonResult = io.decode(JSON.parse(await blob.text()));
      if (jsonResult.isErr) {
        await deleteFromCache(uri);
        throw jsonResult.value;
      }
      const json = jsonResult.value;
      return {
        agenda: json.agenda,
        pieces: json.pieces,
        playlists: json.playlists,
        persons: new Persons(json.persons),
        arrangements: new Arrangements(json.arrangements),
      };
    },
  );
};

const rehorseLoaderOptions = { getter: rL };

export { rehorseLoaderOptions };
