<template>
  <Top />
  <div class="panel">
    <ArrangementEdit
      v-if="edit && arrangement"
      :id="props.arrangement"
      :group="group"
      :arrangement="arrangement"
      :persons="persons"
      @change="change"
      @cancel="cancel"
    />
    <ArrangementEdit
      v-else-if="edit"
      :id="props.arrangement"
      :group="group"
      :persons="persons"
      @change="change"
      @cancel="cancel"
    />
    <template v-else-if="arrangement">
      <div class="panel-heading">
        <div class="level is-mobile">
          <h1 class="level-left">{{ arrangement.title }}</h1>
          <div class="level-right">
            <button
              v-if="store.canEdit(props.group)"
              class="button is-small"
              @click="edit = true"
            >
              Edit
            </button>
          </div>
        </div>
      </div>
      <div v-if="arrangement.composers.length" class="panel-block">
        composed by
        <span
          v-for="composer in arrangement.composers"
          :key="composer"
          class="pl-1"
        >
          {{ persons.people.get(composer)?.name }}
        </span>
      </div>
      <div v-if="arrangement.arrangers.length" class="panel-block">
        arranged by
        <span
          v-for="arranger in arrangement.arrangers"
          :key="arranger"
          class="pl-1"
        >
          {{ persons.people.get(arranger)?.name }}
        </span>
      </div>
      <div v-if="arrangement.lyricists.length" class="panel-block">
        words by
        <span
          v-for="lyricist in arrangement.lyricists"
          :key="lyricist"
          class="pl-1"
        >
          {{ persons.people.get(lyricist)?.name }}
        </span>
      </div>
      <div v-for="part in parts" :key="part.partId">
        <router-link
          v-if="part.a && part.a.length"
          class="panel-block"
          :to="to(part)"
        >
          <span class="panel-icon">🗋</span>
          {{ part.part.name }}
        </router-link>
        <div v-else-if="part.a" class="panel-block">
          <span class="panel-icon">🔇</span>
          {{ part.part.name }}
        </div>
        <div v-else class="panel-block">
          <span class="panel-icon">☒</span>
          {{ part.part.name }}
        </div>
      </div>
      <div>
        <Pieces :group="group" :pieces="pieces" />
      </div>
    </template>
  </div>
</template>
<script setup lang="ts">
import { ref, computed } from "vue";
import { useRouter } from "vue-router";
import { useRehorseStore } from "../store";
import { makePartUrl, getUserParts, APart } from "../cache";
import Top from "../components/Top.vue";
import ArrangementEdit from "../components/ArrangementEdit.vue";
import Pieces from "../components/Pieces.vue";
import type { Arrangement } from "../../shared/rehorse";
import type { ArrangementId } from "../../shared/ids";

const props = defineProps<{
  group: string;
  arrangement: ArrangementId;
}>();

const store = useRehorseStore();
const router = useRouter();
let arrangement = store
  .getArrangements(props.group)
  .value.arrangements.get(props.arrangement);
const edit = ref(!arrangement);
const persons = store.getPersons(props.group);
const pieces = computed(() => {
  const p = new Map();
  if (arrangement) {
    for (const r of arrangement.recordings) {
      p.set(r, store.getPiece(props.group, r));
    }
  }
  return p;
});
const parts = computed((): APart[] => {
  const user = store.user?.username;
  const group = store.getGroup(props.group);
  return getUserParts(user, arrangement, group);
});

async function change(newArrangement: Arrangement) {
  store
    .getArrangements(props.group)
    .value.arrangements.set(props.arrangement, newArrangement);
  await store.save(props.group);
  arrangement = newArrangement;
  edit.value = false;
}
function cancel() {
  if (arrangement) {
    edit.value = false;
  } else {
    router.go(-1);
  }
}
function to(part: APart) {
  let filename;
  if (arrangement?.title) {
    filename = `${arrangement.title} – ${part.part.name}`;
  } else {
    filename = part.part.name;
  }
  return {
    name: "pdf",
    params: {
      filename,
      url: makePartUrl(props.group, props.arrangement, part.partId),
      group: props.group,
    },
  };
}
</script>
