// SPDX-FileCopyrightText: 2023 Jos van den Oever <rehorse@vandenoever.info>
//
// SPDX-License-Identifier: AGPL-3.0-only

import { v4 as uuidv4, validate } from "uuid";
import { TTransform, TRef, TString, Type } from "@sinclair/typebox";
import { uuidSchemaRef } from "./ajv.js";

declare const isIdSymbol: unique symbol;
export type Id = string & { [isIdSymbol]: true };

declare const isConcertId: unique symbol;
export type ConcertId = Id & { [isConcertId]: true };
declare const isRehearsalId: unique symbol;
export type RehearsalId = Id & { [isRehearsalId]: true };
declare const isLocationId: unique symbol;
export type LocationId = Id & { [isLocationId]: true };
declare const isPlaylistId: unique symbol;
export type PlaylistId = Id & { [isPlaylistId]: true };
declare const isPersonId: unique symbol;
export type PersonId = Id & { [isPersonId]: true };
declare const isArrangementId: unique symbol;
export type ArrangementId = Id & { [isArrangementId]: true };
declare const isPartId: unique symbol;
export type PartId = Id & { [isPartId]: true };
declare const isPageAnnotationId: unique symbol;
export type PageAnnotationId = Id & { [isPageAnnotationId]: true };

/* eslint-disable @typescript-eslint/no-unnecessary-type-parameters */
// eslint-disable-next-line @typescript-eslint/no-empty-function
function assertId<T extends Id>(_input: string): asserts _input is T {}

export function isId<T extends Id>(input: string): input is T {
  return validate(input);
}

export function createId<T extends Id>(): T {
  const id = uuidv4();
  assertId<T>(id);
  return id;
}

function toId<T extends Id>(id: string): T {
  assertId<T>(id);
  return id;
}

declare const isSha256Symbol: unique symbol;
export type Sha256 = string & { [isSha256Symbol]: true };

declare const isPieceId: unique symbol;
export type PieceId = Sha256 & { [isPieceId]: true };

const RE_SHA256 = /^[0-9a-f]{64}$/;

export function isSha256<T extends Sha256>(input: string): input is T {
  return RE_SHA256.test(input);
}
/* eslint-enable @typescript-eslint/no-unnecessary-type-parameters */

export function createIdSchema<T extends Id>(): TTransform<TRef<TString>, T> {
  return Type.Transform(uuidSchemaRef)
    .Decode((v) => toId<T>(v))
    .Encode((v) => v);
}
