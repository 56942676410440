<template>
  <div class="panel">
    <div>
      <input
        ref="fileInput"
        :disabled="uploading"
        type="file"
        multiple="true"
        :accept="accept"
        @change="change"
      />
      <input
        type="submit"
        :disabled="!fileReady || uploading"
        value="Upload"
        @click="upload"
      />
    </div>
    <template v-if="uploading">
      <div>uploading...</div>
      <progress class="progress" :value="progress" max="100">
        {{ progress }}
      </progress>
    </template>
    <div v-if="error">{{ error }}</div>
  </div>
</template>
<script setup lang="ts">
import { ref, Ref } from "vue";
import axios from "axios";

const props = defineProps<{
  accept: string;
  postUrl: string;
}>();
const emit =
  defineEmits<
    (e: "uploaded", index: number, total: number, response: unknown) => void
  >();

const fileInput: Ref<HTMLInputElement | undefined> = ref();
const error = ref("");
const uploading = ref(false);
const progress = ref(0);
const fileReady = ref(false);

function change() {
  const files = fileInput.value?.files;
  fileReady.value = files?.[0] != null;
}

async function upload() {
  error.value = "";
  if (!fileInput.value) {
    error.value = "No file input is found.";
    return;
  }
  if (!fileInput.value.files) {
    return;
  }
  uploading.value = true;
  const files = Array.from(fileInput.value.files);
  let i = 0;
  for (const file of files) {
    const quotedString = file.name
      .replace(/[\u{0080}-\u{FFFF}]/gu, "")
      .replaceAll('"', '\\"');
    try {
      const response = await axios.request({
        method: "post",
        url: props.postUrl,
        data: file,
        headers: {
          "Content-Disposition": `attachment; filename="${quotedString}"`,
        },
        onUploadProgress: (p) => {
          let percent = 100 * i;
          if (p.total) {
            percent += (100 * p.loaded) / p.total;
          } else {
            progress.value = 0;
          }
          progress.value = Math.round(percent / files.length);
        },
      });
      emit("uploaded", i, files.length, response.data);
    } catch (err) {
      if (err instanceof Error) {
        error.value = err.toString();
      }
      break;
    }
    ++i;
  }
  progress.value = 100;
  // clear the <input/> after the files have been uploaded
  fileInput.value.value = "";
  uploading.value = false;
}
</script>
