<template>
  <DrawingArea
    v-for="(canvas, index) in props.canvases"
    :key="canvas.rect.y"
    class="drawingarea"
    :style="{
      top: canvas.rect.top + 'px',
      width: canvas.rect.width + 'px',
      left: canvas.rect.left + 'px',
      height: canvas.rect.height + 'px',
    }"
    :width-pt="canvas.pageWidthPt"
    :height-pt="canvas.pageHeightPt"
    :scale
    :mode
    :color
    :annotations="canvas.annotations"
    @change="(a: PageAnnotations) => emit('change', index, a)"
    @move-item="
      (id: string, offset: Point) => emit('moveItem', index, id, offset)
    "
    @mode-change="modeChange"
  >
  </DrawingArea>
</template>
<script setup lang="ts">
import DrawingArea from "./DrawingArea.vue";
import { PageAnnotations, Point } from "../../shared/annotations";
import { Canvas } from "./canvas";
import { PdfViewMode } from "./PdfViewOptions";
const props = defineProps<{
  canvases: Canvas[];
  scale: number;
  mode: PdfViewMode;
  color: string;
}>();
const emit = defineEmits<{
  (e: "change", canvas: number, annotations: PageAnnotations): void;
  (e: "moveItem", canvas: number, id: string, offset: Point): void;
  (e: "modeChange", mode: PdfViewMode): void;
}>();
function modeChange(mode: PdfViewMode) {
  emit("modeChange", mode);
}
</script>
<style>
div.drawingarea {
  position: absolute;
}
</style>
