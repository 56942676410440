// SPDX-FileCopyrightText: 2023 Jos van den Oever <rehorse@vandenoever.info>
//
// SPDX-License-Identifier: AGPL-3.0-only

import type { Arrangement, PlaylistItem } from "../../shared/rehorse";
import type { Persons } from "./people";
import type { ArrangementId, PersonId } from "../../shared/ids";
import { createId } from "../../shared/ids";
import { ComputedRef } from "vue";

export function newArrangement(): Arrangement {
  return {
    title: "",
    composers: [],
    arrangers: [],
    lyricists: [],
    recordings: [],
    pdfs: [],
    parts: new Map(),
  };
}

export class Arrangements {
  arrangements: Map<ArrangementId, Arrangement>;
  constructor(arrangements: Map<ArrangementId, Arrangement>) {
    this.arrangements = arrangements;
  }
  addArrangement(arrangement: Arrangement): ArrangementId {
    const id = createId<ArrangementId>();
    this.arrangements.set(id, arrangement);
    const sorted = [...this.arrangements.entries()].sort((a, b) =>
      a[1].title.localeCompare(b[1].title),
    );
    this.arrangements = new Map(sorted);
    return id;
  }
}

export interface PlaylistOption {
  id: number;
  label: string;
  arrangement?: Arrangement;
  item: PlaylistItem;
}

// Return the arrangements from the store as a list of items with
// unique titles. These can be shown in a selector.
export const getArrangementsAsOptions = (
  arrangements: ComputedRef<Arrangements>,
  persons: Persons,
  idCounter: number,
): [PlaylistOption[], number] => {
  // get the titles that are not unique
  const labels = getLabels(arrangements.value);
  const nonUniqueLabels = getNonUniqueLabels(labels);
  const uniqueLabels = getAllUniqueLabels(
    arrangements.value,
    persons,
    nonUniqueLabels,
  );
  const options: PlaylistOption[] = [];
  for (const [id, arrangement] of arrangements.value.arrangements.entries()) {
    let label = uniqueLabels.get(id);
    if (!label) {
      label = arrangement.title;
    }
    options.push({
      id: idCounter++,
      label,
      arrangement,
      item: {
        arrangement: id,
      },
    });
  }
  return [options, idCounter];
};

// Retrieve all the labels
const getLabels = (
  arrangements: Arrangements,
): Map<string, ArrangementId[]> => {
  const labels = new Map<string, ArrangementId[]>();
  for (const [id, arrangement] of arrangements.arrangements.entries()) {
    const l = labels.get(arrangement.title);
    if (l) {
      l.push(id);
    } else {
      labels.set(arrangement.title, [id]);
    }
  }
  return labels;
};

// Retrieve all the labels that are not unique
const getNonUniqueLabels = (
  labels: Map<string, ArrangementId[]>,
): Map<string, ArrangementId[]> => {
  const nonUniqueLabels = new Map<string, ArrangementId[]>();
  for (const [label, ids] of labels.entries()) {
    if (ids.length > 1) {
      nonUniqueLabels.set(label, ids);
    }
  }
  return nonUniqueLabels;
};

// Map a list of arrangementsIds to a list of list of names
// `toPersons` provides a mapping from arrangement to a list of persons
// such as arrangers, composers or lyricists.
const getPersonNames = (
  arrangements: Arrangements,
  persons: Persons,
  ids: ArrangementId[],
  toPersons: (a: Arrangement) => PersonId[],
): Map<ArrangementId, string[]> => {
  const names = new Map<ArrangementId, string[]>();
  for (const id of ids) {
    const arrangement = arrangements.arrangements.get(id);
    if (!arrangement) {
      continue;
    }
    names.set(
      id,
      toPersons(arrangement)
        .map((personId: PersonId) => persons.people.get(personId)?.name ?? "")
        // remove empty strings
        .filter((s: string) => s),
    );
  }
  return names;
};

// Create unique labels by appending names of persons that worked on it
const getAllUniqueLabels = (
  arrangements: Arrangements,
  persons: Persons,
  nonUniqueLabels: Map<string, ArrangementId[]>,
): Map<ArrangementId, string> => {
  const infoCreators = [
    (ids: ArrangementId[]) =>
      getPersonNames(
        arrangements,
        persons,
        ids,
        (a: Arrangement) => a.arrangers,
      ),
    (ids: ArrangementId[]) =>
      getPersonNames(
        arrangements,
        persons,
        ids,
        (a: Arrangement) => a.composers,
      ),
    (ids: ArrangementId[]) =>
      getPersonNames(
        arrangements,
        persons,
        ids,
        (a: Arrangement) => a.lyricists,
      ),
    (ids: ArrangementId[]): Map<ArrangementId, string[]> => {
      const versions = new Map<ArrangementId, string[]>();
      for (const id of ids) {
        versions.set(id, [arrangements.arrangements.get(id)?.version ?? ""]);
      }
      return versions;
    },
  ];
  const labels = new Map<ArrangementId, string>();
  for (const [label, ids] of nonUniqueLabels.entries()) {
    for (const infoCreator of infoCreators) {
      const extraInfo = infoCreator(ids);
      const l = getUniqueLabels(label, extraInfo, ids);
      if (l) {
        for (const [id, newLabel] of l.entries()) {
          labels.set(id, newLabel);
        }
      }
      break;
    }
  }
  return labels;
};

// Pass in an array of ArrangmentsIds for arrays that have the same title.
// Try to make unique labels from the people that were part of creating the
// arrangement. If unique labels can be made, return these. Otherwise,
// return undefined.
const getUniqueLabels = (
  baseLabel: string,
  extraInfo: Map<ArrangementId, string[]>,
  ids: ArrangementId[],
): Map<ArrangementId, string> | undefined => {
  // try if the list of people is unique for each arrangement
  const labels = new Map();
  const newLabels = new Map();
  for (const id of ids) {
    const info = extraInfo.get(id);
    let label = baseLabel;
    if (info?.length) {
      label += " (";
      for (const [index, i] of info.entries()) {
        const isLast = index === info.length - 1;
        if (i) {
          label += i;
          if (isLast) {
            label += ")";
          } else {
            label += ", ";
          }
        }
      }
    }
    if (labels.has(label)) {
      // adding the person names does not result in unique labels, return
      // undefined
      return;
    }
    newLabels.set(id, label);
  }
  return newLabels;
};
